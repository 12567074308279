<div fxLayout="row" class="guest-notify-party-container">
    <div
      fxLayout="column"
      fxFlex="5"
      fxLayoutAlign="stretch"
      class="accent-500"
    ></div>
    <div fxLayout="column" fxFlex="95" class="p-24">
      <div fxLayout="row" fxLayoutAlign="start" class="mb-8">
        <h1 class="accent-700-fg font-weight-900">
          Add Guest Notify Party Information
        </h1>
      </div>
      <div fxLayout="row">
        <div fxLayout="column">
          <label class="board-list-item">
            <div class="board-name">
              <mat-icon class="s-50" style="color: #b2b2b2;"
                >add_photo_alternate
              </mat-icon>
            </div>
            <div class="companyLogo"></div>
            <div class="UploadCompany">
              Upload or Drag and Drop
            </div>
            <div class="UploadCompany">Format: jpg, png</div>
            <div class="UploadCompany">Size: 5 mb (max)</div>
            <input type="file" accept="image/*" style="display: none;" />
          </label>
        </div>
        <div fxLayout="column" class="pt-14" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" floatLabel="always" class="mb-8">
            <mat-label>Company Name</mat-label>
            <input matInput />
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-8">
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="48">
              <mat-label>First Name</mat-label>
              <input matInput />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="48">
              <mat-label>Last Name</mat-label>
              <input matInput />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-8">
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="48">
              <mat-label>Email Address</mat-label>
              <input matInput />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="48">
              <mat-label>Telephone Number</mat-label>
              <span matPrefix>
                <mat-icon>add</mat-icon>
              </span>
              <mat-select name="number" class="number">
                <mat-option value="63">63</mat-option>
                <mat-option value="09">09</mat-option>
              </mat-select>
              <input matInput />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-8">
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="48">
              <mat-label>Mobile Number</mat-label>
              <span matPrefix>
                <mat-icon>add</mat-icon>
              </span>
              <mat-select name="number" class="number">
                <mat-option value="63">63</mat-option>
                <mat-option value="09">09</mat-option>
              </mat-select>
              <input matInput />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="48">
              <mat-label>Fax Number</mat-label>
              <input matInput />
            </mat-form-field>
          </div>
          <mat-form-field appearance="outline" floatLabel="always" class="mb-8">
            <mat-label>Complete Address</mat-label>
            <input matInput />
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-8">
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="48">
              <mat-label>Country</mat-label>
              <input matInput />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="48">
              <mat-label>Province / State</mat-label>
              <input matInput />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-8">
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="48">
              <mat-label>City</mat-label>
              <input matInput />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="48">
              <mat-label>Zip Code</mat-label>
              <input matInput />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <mat-checkbox>Add as <b>Guest Notify Party</b>?</mat-checkbox>
          </div>
        </div>
      </div>
      <div fxLayout="column-reverse" class="h-100-p">
        <div fxLayout="row" fxLayoutAlign="space-between">
          <button mat-button class="grey-600" (click)="openSelectTypeNotifyParty()">
            <mat-icon>
              keyboard_arrow_left
            </mat-icon>
            Back
          </button>
          <button mat-button class="blue-500" (click)="openSelectNotifyParty()">
            Proceed
            <mat-icon>
              keyboard_arrow_right
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  