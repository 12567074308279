import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProtectedImagePipe } from 'app/core/pipe/protected-image/protected-image.pipe';
import { FuseUtils } from "@fuse/utils";
import Glide, {
  Controls,
  Breakpoints,
} from "@glidejs/glide/dist/glide.modular.esm";
@Component({
  selector: "app-multiple-fileviewer",
  templateUrl: "./multiple-fileviewer.component.html",
  styleUrls: ["./multiple-fileviewer.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MultipleFileviewerComponent implements OnInit {
  attachments: any[];
  images: any[];
  
  constructor(
    public dialogRef: MatDialogRef<MultipleFileviewerComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) _data: any,
    private protectedImage: ProtectedImagePipe
  ) {
    this.images = _data.file;

    this.getAttachmentFile();
  
  }

  getAttachmentFile(): any {
    this.attachments = this.images.map((attachment) => {
      return FuseUtils.fileParser(attachment);
    }); 
    setTimeout(() => {
      if (this.attachments.length > 1) {
        this.setGlide(this.attachments.length);
      }
    }, 500);
  }

  setGlide(data): void {
    if (data == 2) {
      new Glide(".glide", {
        type: "slider",
        startAt: 0,
        perView: data,
      }).mount({ Controls, Breakpoints });
    } else {
      new Glide(".glide", {
        type: "slider",
        startAt: 0,
        perView: 3,
      }).mount({ Controls, Breakpoints });
    }
  }

  selectAttachment(data) {
    if (data.filePath) {
      const checkIframe = document.querySelector("#iframe-pdf");
      if (checkIframe) {
        checkIframe.remove();
      }
      const targetElement = document.querySelector("#iframeContainer");
      let iframe: any;
      this.protectedImage.transform(data.filePath ? data.filePath : data ).then(presignedDocument => {
        iframe = document.createElement('iframe');
        iframe.style.cssText = 'width:100%; height: 300px;';
        iframe.id = 'iframe-pdf';
        iframe.src = presignedDocument;
        targetElement.appendChild(iframe);
      })
    }
  }

  ngOnInit(): void {
   
  }

 
}