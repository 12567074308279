import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Component({
  selector: 'app-sos-modal',
  templateUrl: './sos-modal.component.html',
  styleUrls: ['./sos-modal.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class SosModalComponent implements OnInit {
  vehicle: any [] =[];



  constructor(
  
  public matDialogRef: MatDialogRef<SosModalComponent>,
  @Inject(MAT_DIALOG_DATA) private _data: any,
  private router: Router,
  private _fuseSplashScreenService: FuseSplashScreenService,
  )
  {
    this.vehicle.push(this._data.data);
  }

  ngOnInit(): void {
  }

  close(){
    this.matDialogRef.close()
  }

  route(){
    this._fuseSplashScreenService.show()
    setTimeout(() => {
      this.router.navigate([
        `/main/transaction-management/booking-reservations/trip-management/`,
        this._data.data?.JobOrderNo,
        this._data.data?.TripNo,
        0
      ]);
      this._fuseSplashScreenService.hide()
    }, 500);
    this.close();
  }

}
