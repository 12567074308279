import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
  {
    id: "applications",
    title: "Applications",
    type: "group",
    icon: "apps",
    children: [
      // {
      //   id: 'dashboard',
      //   title: 'Dashboard',
      //   translate: '',
      //   type: 'item',
      //   icon: 'xlog-dashboard',
      //   url: '/main/dashboard',
      // },
      {
        id: "profile",
        title: "Profile",
        translate: "",
        type: "collapsable",
        icon: "xlog-profile",
        children: [
          {
            id: "company-profile",
            title: "Company Profile",
            type: "item",
            url: "/main/profile/company-profile",
          },
          {
            id: "my-profile",
            title: "My Profile",
            type: "item",
            url: "/main/profile/my-profile",
          },
        ],
      },
      {
        // id: '',
        // title: '',
        // translate: '',
        // type: 'collapsable',
        // icon: 'xlog-transaction-management',
        // url: '/main/transactions',
        id: "transaction-management",
        title: "Transaction Management",
        type: "collapsable",
        icon: "xlog-transaction-management",
        url: "/main/transactions",
        children: [
          {
            id: "booking-reservations",
            title: "Booking Reservations",
            type: "item",
            url: "/main/transaction-management",
          },
          {
            id: "service-details",
            title: "Service Details",
            type: "collapsable",
            children: [
              {
                id: "sea-freight",
                title: "Sea Freight",
                type: "item",
                url: "/transaction-management/service-details/sea-freight",
              },
              {
                id: "trucking",
                title: "Trucking",
                type: "item",
                url: "/transaction-management/service-details/trucking",
              },
            ],
          },
          {
            id: "trip-tickets",
            title: "Trip Tickets",
            type: "item",
            url: "/transaction-management/trip-tickets",
          },
          {
            id: "associated-reservations",
            title: "Associated Reservations",
            type: "collapsable",
            children: [
              {
                id: "associated-inbound",
                title: "Inbound",
                type: "item",
                url: "/transaction-management/associated-reservation/inbound",
              },
              {
                id: "associated-outbound",
                title: "Outbound",
                type: "item",
                url: "/transaction-management/associated-reservation/outbound",
              },
            ],
          },

          {
            id: "draft-and-template",
            title: "Drafts and Templates",
            type: "collapsable",
            children: [
              {
                id: "drafts",
                title: "Drafts",
                type: "item",
                url: "/main/draft-and-template/drafts",
              },
              {
                id: "templates",
                title: "Templates",
                type: "item",
                url: "/main/draft-and-template/templates",
              },
            ],
          },
        ],
      },
      // {
      //   id: "container-management",
      //   title: "Container Management",
      //   translate: "",
      //   type: "item",
      //   icon: "xlog-container-management",
      //   url: "/main/container-management",
      // },
      {
        id: "container-management",
        title: "Container Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-container-management",
        children: [
          {
            id: "container-management-list",
            title: "Containers",
            type: "item",
            url: "/main/container-management/containers",
          },
          {
            id: "slot-booking",
            title: "Slot Booking",
            type: "item",
            url: "/main/container-management/slot-booking",
          },
        ],
      },
      {
        id: "access-management",
        title: "Access Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-access-management",
        children: [
          {
            id: "user",
            title: "User",
            type: "item",
            url: "/main/access-management/user",
          },
          {
            id: "group",
            title: "Group",
            type: "item",
            url: "/main/access-management/group",
          },
        ],
      },
      {
        id: "finance-management",
        title: "Finance Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-billing-management",
        children: [
          {
            id: "payable-receivable",
            title: "Payable & Receivable",
            type: "item",
            url: "/main/finance-management/payable-receivable",
          },
        ],
      },
      {
        id: "settings",
        title: "Settings",
        translate: "",
        type: "collapsable",
        icon: "xlog-settings",
        children: [
          {
            id: "contacts",
            title: "Contacts and Preferred provider",
            type: "item",
            url: "/main/settings/contacts",
          },
          // {
          //   id: 'document-template',
          //   title: 'Document Template',
          //   type: 'item',
          //   url: '/main/settings/document', //no component yet
          // },
          // {
          //   id: 'email-template',
          //   title: 'Email Template',
          //   type: 'item',
          //   url: '/main/settings/email', //no component yet
          // },
        ],
      },
      {
        id: "other-facilities",
        title: "Other Facilities",
        translate: "",
        type: "collapsable",
        icon: "xlog-billing-management",
        children: [
          {
            id: "facilities-loan",
            title: "Loan",
            type: "item",
            url: "/main/other-facilities/loan",
          },
          {
            id: "facilities-insurance",
            title: "Insurance",
            type: "item",
            url: "/main/other-facilities/insurance",
          },
        ],
      },
    ],
  },
];

export const navigationKYC: FuseNavigation[] = [
  {
    id: "applications",
    title: "Applications",
    type: "group",
    icon: "apps",
    children: [
      // {
      //   id: 'dashboard',
      //   title: 'Dashboard',
      //   translate: '',
      //   type: 'item',
      //   icon: 'xlog-dashboard',
      //   url: '/main/dashboard',
      // },
      {
        id: "profile",
        title: "Profile",
        translate: "",
        type: "collapsable",
        icon: "xlog-profile",
        children: [
          {
            id: "company-profile",
            title: "Company Profile",
            type: "item",
            url: "/main/profile/company-profile",
          },
          {
            id: "my-profile",
            title: "My Profile",
            type: "item",
            url: "/main/profile/my-profile",
          },
        ],
      },
    ],
  },
];
