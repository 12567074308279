import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RootState, selectUser } from 'app/core/store';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private frame = document.getElementById('frame');
  private iframe;

  user: any;

  constructor(
    private http: HttpClient,
    private store: Store<RootState>
  ) {
    this.iframe = document.createElement('iframe');
    this.iframe.setAttribute('id', 'middleware-iframe');
    this.iframe.style.display = 'none';
    this.iframe.src = environment.ssoUrl;
    this.frame.appendChild(this.iframe);

    this.store.pipe(select(selectUser)).pipe(take(1))
      .subscribe(res => {
        if (res) {
          this.user = res.user;
        }
      })
  }

  getTokenOnInit() {
    return new Promise<any>((resolve, reject) => {
      const messageHandler = (event) => {
        const {
          action,
          key,
          value
        } = event.data;

        if (action === 'returnData') {
          localStorage.setItem(key, JSON.stringify(value));
          const {
            cognito: {
              authenticationResult: {
                refreshToken
              }
            }
          } = value;

          if (localStorage.getItem('refreshToken') == "null" || localStorage.getItem('refreshToken') == null) {
            localStorage.setItem("refreshToken", refreshToken);
          }
        }
        resolve('');
      }

      this.iframe.onload = () => {
        window.addEventListener('message', messageHandler, false);
        this.iframe.contentWindow.postMessage({
          action: 'get',
          key: 'token',
        }, '*');
      }
    })
  }

  setToken(data) {
    return new Promise<void>((resolve, reject) => {
      this.iframe.contentWindow.postMessage({
        action: 'save',
        key: 'token',
        value: data
      }, '*');
      resolve();
    })
  }

  getToken() {
    return new Promise<any>((resolve, reject) => {
      const messageHandler = (event) => {
        const {
          action,
          key,
          value
        } = event.data;

        if (action === 'returnData') {
          localStorage.setItem(key, JSON.stringify(value));
        }
        resolve('');
      }

      window.addEventListener('message', messageHandler, false);
      this.iframe.contentWindow.postMessage({
        action: 'get',
        key: 'token',
      }, '*');
    })
  }

  generateNewToken() {
    let refreshToken = localStorage.getItem('refreshToken');
    return this.http.post(`${environment.authManagementAuthentication}/api/v1/auth/refresh/${this.user && this.user.username}`, {
      refreshToken
    });
  }

}
