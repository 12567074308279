import { Action } from '@ngrx/store';
import { CreateRequestBookingSeaFreightInbound } from 'app/core/models/booking-sea-freight-inbound.model';

export enum BookingSeaFreightInboundActionTypes {
  SetBookingSeaFreightInbound = '[CreateRequestBookingSeaFreightInbound] Set CreateRequestBookingSeaFreightInbound',
  RemoveBookingSeaFreightInbound = '[CreateRequestBookingSeaFreightInbound] Remove CreateRequestBookingSeaFreightInbound',
}

export class SetBookingSeaFreightInbound implements Action {
  readonly type = BookingSeaFreightInboundActionTypes.SetBookingSeaFreightInbound;
  constructor(public payload: CreateRequestBookingSeaFreightInbound) {}
}

export class RemoveBookingSeaFreightInbound implements Action {
  readonly type = BookingSeaFreightInboundActionTypes.RemoveBookingSeaFreightInbound;
  constructor() {}
}

export type BookingSeaFreightInboundActions = SetBookingSeaFreightInbound | RemoveBookingSeaFreightInbound;
