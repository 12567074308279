<div id="reset-password" class="inner-scroll" fxLayout="row" fxLayoutAlign="center center">

    <div id="reset-password-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="reset-password-form">

            <div class="logo2" >
                <img src="assets/icons/logos/xlog-logo-2x.png">
            </div>

            <div class="title">CHANGE YOUR PASSWORD</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Enter Current Password</mat-label>
                    <input matInput formControlName="currentPassword"
                    maxlength="100"
                    autocomplete='new-password'
                    required
                    [type]="Currenthide ? 'password' : 'text'"
                    >
                    <mat-icon matSuffix  class="secondary-text" (click)="Currenthide = !Currenthide">{{Currenthide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('currentPassword').hasError('required')">
                        Current Password is required
                    </mat-error>
                    
                    <mat-error *ngIf="!resetPasswordForm.get('currentPassword').hasError('required') && 
                    resetPasswordForm.get('currentPassword').hasError('minlength')">
                        Current Password must be at least 8 characters
                    </mat-error>
                </mat-form-field>
                
                <mat-form-field appearance="outline"  style="width: 100%" style="margin-top:1rem;">
                    <mat-label>New Password</mat-label>
                    <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
                    <input matInput #password
                           [type]="toggle.type"
                           maxlength="100"
                           formControlName="password" autocomplete='new-password'
                           required
                           placeholder="Password">

                    <mat-error *ngIf="resetPasswordForm.get('password').hasError('minlength')">
                        Password must be at least 8 characters
                    </mat-error>

                  </mat-form-field>

                  <!--@angular-material-extensions/password-strength's main component-->
                  <mat-password-strength #passwordComponent
                  
                                         (onStrengthChanged)="onStrengthChanged($event)"
                                         [password]="password.value">
                  </mat-password-strength>
                  <!--Password's strength info-->
                  <mat-password-strength-info
                    
                    [passwordComponent]="passwordComponent">
                  </mat-password-strength-info>

                <mat-form-field appearance="outline" style="margin-top:1rem;">
                    <mat-label>Re-Enter New Password</mat-label>
                    <input matInput formControlName="passwordConfirm"
                    [type]="hideConfirm ? 'password' : 'text'"
                    >
                    <mat-icon matSuffix  class="secondary-text" (click)="hideConfirm = !hideConfirm">{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <!-- <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon> -->
                    <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                        Password confirmation is required
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Password does not match
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching') && 
                    !resetPasswordForm.get('passwordConfirm').hasError('required') && 
                    resetPasswordForm.get('passwordConfirm').hasError('minlength')">
                        Password Confirm must be at least 8 characters
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent"
                        aria-label="CHANGE MY PASSWORD" (click)="submit()" [disabled]="resetPasswordForm.invalid">
                    SAVE
                </button>
            </form>
        </div>
    </div>
</div>