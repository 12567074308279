<div class="inner-scroll" fxLayout="row" fxFlex="100%">
  <div
    id="claims-modal"
    fxFlex="1 0 auto"
    fusePerfectScrollbar
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <div
      id="claims-pop-up-page"
      fxLayout="column"
      fxLayoutAlign="space-between start"
      fxFlex="1 0  auto"
      class="pb-24"
    >
      <div
        fxLayout="column"
        class="s-100 text-uppercase accent-bg w-100-p"
        fxLayoutAlign="stretch"
        fxFlex="100%"
      >
        &nbsp;
      </div>
      <form
        [formGroup]="claimsForm"
        #addClaimsForm="ngForm"
        fxLayout="column"
        class="w-100-p"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="1 0  auto"
          fxLayoutGap="12px"
          class="p-24 w-100-p"
        >
          <div class="h3 font-size-24">Enter Reference No/s.</div>
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxFlex="0 1 auto"
            class="p-12 pt-8 w-100-p"
            fxLayoutGap="8px"
          >
            <div
              fxLayout="column"
              fxLayoutAlign="space-between start"
              fxFlex="0 1 auto"
              class="px-32 w-100-p"
              fxLayoutGap="8px"
            >
              <div>
                You are about to claim transaction with the following details:
              </div>
              <div class="w-100-p pt-12" *ngIf="initialData === 'BLNumber'">
                <mat-form-field
                  appearance="outline"
                  floatLabel="always"
                  class="w-100-p mat-field-medium"
                >
                  <mat-label> BL Number </mat-label>
                  <input
                    matInput
                    type="text"
                    maxlength="20"
                    required
                    formControlName="blNumber"
                    autocapitalize="off"
                    autocomplete="off"
                    autocorrect="off"
                    spellcheck="off"
                  />
                </mat-form-field>
              </div>
            </div>

            <div
              *ngIf="hasError || isSuccess"
              class="accent-50-bg primary-800-fg pt-12 mb-12 w-100-p"
              fxLayout="column"
              fxLayoutAlign="space-between start"
              fxFlex="1 0 auto"
              fxLayoutGap="12px"
            >
              <div
                fxLayout="column"
                fxLayoutAlign="space-between start"
                fxFlex="0 1 auto"
                class="px-24 w-100-p pb-12"
                fxLayoutGap="8px"
              >
                <div
                  class="font-size-12"
                  [ngClass]="hasError ? 'red-400-fg' : 'green-300-fg'"
                >
                  {{
                    hasError
                      ? "Record cannot be found! Enter the Reference number below to validate the BL number."
                      : "Record Found!"
                  }}
                </div>
                <mat-form-field
                  *ngIf="hasError"
                  appearance="outline"
                  floatLabel="always"
                  class="w-100-p mat-field-medium pt-12"
                >
                  <mat-label> Container Number </mat-label>
                  <input
                    matInput
                    type="text"
                    required
                    minlength="1"
                    maxlength="18"
                    formControlName="containerNumber"
                    autocapitalize="off"
                    autocomplete="off"
                    autocorrect="off"
                    spellcheck="off"
                  />
                </mat-form-field>

                <div
                  *ngIf="isSuccess"
                  fxflex="column"
                  fxlayoutalign="space-between start"
                  flex="1 0 auto "
                  fxLayoutGap="24px"
                  class="pl-32 w-100-p h-auto"
                >
                  <div
                    fxflex="row"
                    fxlayoutalign="start start"
                    flex="1 0 auto"
                    fxlayoutgap="8px"
                    class="w-50-p"
                  >
                    <div class="text-bold text-left" fxFlex="50">BL Number</div>
                    <div class="text-left" fxFlex>
                      {{ claimDetails?.blNumber }}
                    </div>
                  </div>
                  <div
                    fxflex="row"
                    fxlayoutalign="space-between start"
                    flex="1 0 auto"
                    fxlayoutgap="8px"
                    class="w-100-p"
                  >
                    <div fxFlex="50">
                      <div class="text-bold text-left" fxFlex="50">POL</div>
                      <div class="text-left" fxFlex>
                        {{ claimDetails?.portOfLoadingUnLoCode }}
                      </div>
                    </div>
                    <div fxFlex="50">
                      <div class="text-bold text-left" fxFlex="50">POD</div>
                      <div class="text-left" fxFlex>
                        {{ claimDetails?.portOfDischargeUnLoCode }}
                      </div>
                    </div>
                  </div>
                  <div
                    fxflex="row"
                    fxlayoutalign="space-between start"
                    flex="1 0 auto"
                    fxlayoutgap="8px"
                    class="w-100-p"
                  >
                    <div fxFlex="50">
                      <div class="text-bold text-left" fxFlex="50">
                        Vessel Name
                      </div>
                      <div class="text-left" fxFlex>
                        {{ claimDetails?.vesselName || "-" }}
                      </div>
                    </div>
                    <div fxFlex="50">
                      <div class="text-bold text-left" fxFlex="50">
                        Vessel Number
                      </div>
                      <div class="text-left" fxFlex>
                        {{ claimDetails?.voyageNumber || "-" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="w-100-p" *ngIf="initialData === 'BookingNumber'">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="w-100-p"
              >
                <mat-label> Booking Number </mat-label>
                <input
                  matInput
                  type="text"
                  minlength="1"
                  maxlength="20"
                  required
                  formControlName="bookingNumber"
                  autocapitalize="off"
                  autocomplete="off"
                  autocorrect="off"
                  spellcheck="off"
                />
              </mat-form-field>
            </div>
            <div class="w-100-p relative">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="w-100-p"
              >
                <mat-label> Container Number </mat-label>
                <input
                  matInput
                  type="text"
                  required
                  minlength="1"
                  maxlength="18"
                  formControlName="containerNumber"
                  autocapitalize="off"
                  autocomplete="off"
                  autocorrect="off"
                  spellcheck="off"
                />
              </mat-form-field>
              <div class="font-size-12 absolute -mt-20 svgPath">
                <mat-icon
                  class="s-18 position-relative"
                  svgIcon="xlog-info-icon"
                ></mat-icon>
                Note: Input any (1) One Container
              </div>
            </div> -->

            <div
              *ngIf="!isSuccess"
              fxLayoutAlign="space-evenly center"
              fxLayout="row"
              fxLayoutGap="12px"
            >
              <div>
                <mat-checkbox
                  class="mt-24 h6"
                  [checked]="isChecked"
                  (change)="blCheckbox($event)"
                  (click)="$event.stopPropagation()"
                >
                </mat-checkbox>
              </div>

              <div class="text-wrap">
                I authorize the use of the reference/s in accordance with the
                <a
                  href="https://web.xlog.net/terms-and-conditions"
                  target="_blank"
                >
                  <span class="accent-fg">Terms and Condition</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
          <button mat-button class="grey-600 mr-8" (click)="onClose()">
            <mat-icon> clear </mat-icon>
            {{ isSuccess ? "Back" : "Close" }}
          </button>
          <button
            *ngIf="!isSuccess"
            mat-button
            class="blue-500"
            (click)="submit()"
            [disabled]="checkValidation()"
          >
            <mat-icon> keyboard_arrow_right </mat-icon>
            Process
          </button>
          <button
            *ngIf="isSuccess"
            mat-button
            class="blue-500"
            (click)="createClaim()"
          >
            <mat-icon> keyboard_arrow_right </mat-icon>
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
