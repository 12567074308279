import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { NgxDropzoneModule } from "ngx-dropzone";
import { BlackboxComponentComponent } from "./blackbox-component/blackbox-component.component";
import { ChatSupportComponent } from "./chat-support/chat-support.component";
import { ClaimsComponent } from "./claims/claims.component";
import { GuestConsigneeDialogComponent } from "./guest-consignee-dialog/guest-consignee-dialog.component";
import { GuestNotifyPartyDialogComponent } from "./guest-notify-party-dialog/guest-notify-party-dialog.component";
import { GuestShipperDialogComponent } from "./guest-shipper-dialog/guest-shipper-dialog.component";
import { KycNotificationComponent } from "./kyc-notification/kyc-notification.component";
import { MultipleFileviewerComponent } from "./multiple-fileviewer/multiple-fileviewer.component";
import { RegisteredShipperDialogComponent } from "./registered-shipper-dialog/registered-shipper-dialog.component";
import { RouteCompletionComponent } from "./route-completion/route-completion.component";
import { SelectConsigneeDialogComponent } from "./select-consignee-dialog/select-consignee-dialog.component";
import { SelectNotifyPartyDialogComponent } from "./select-notify-party-dialog/select-notify-party-dialog.component";
import { SelectShipperDialogComponent } from "./select-shipper-dialog/select-shipper-dialog.component";
import { SelectTypeConsigneeDialogComponent } from "./select-type-consignee-dialog/select-type-consignee-dialog.component";
import { SelectTypeNotifyPartyDialogComponent } from "./select-type-notify-party-dialog/select-type-notify-party-dialog.component";
import { SelectTypeShipperDialogComponent } from "./select-type-shipper-dialog/select-type-shipper-dialog.component";
import { SelectTruckingTypeDialogComponent } from "./select-trucking-type-dialog/select-trucking-type-dialog.component";

@NgModule({
  imports: [
    FuseSharedModule,
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatMenuModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatDividerModule,
    NgxDropzoneModule,
    MatRadioModule,
  ],
  exports: [BlackboxComponentComponent, ChatSupportComponent],
  declarations: [
    SelectNotifyPartyDialogComponent,
    SelectConsigneeDialogComponent,
    SelectShipperDialogComponent,
    SelectTypeConsigneeDialogComponent,
    SelectTypeShipperDialogComponent,
    GuestShipperDialogComponent,
    GuestConsigneeDialogComponent,
    GuestNotifyPartyDialogComponent,
    RegisteredShipperDialogComponent,
    SelectTypeNotifyPartyDialogComponent,
    KycNotificationComponent,
    MultipleFileviewerComponent,
    ChatSupportComponent,
    BlackboxComponentComponent,
    RouteCompletionComponent,
    ClaimsComponent,
    SelectTruckingTypeDialogComponent,
  ],
})
export class ReusableModule {}
