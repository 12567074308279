<div id="blackbox" class="blackbox-icon text-right">
  <img
    width="90"
    height="85"
    class="mb-8 mr-8 responsiveBlckBox cursor-pointer"
    *ngIf="isShowChatHead"
    [@animate]="{ value: '*', params: { delay: '100ms', x: '25px' } }"
    src="assets/icons/booking-reservation/blackbox-icon.svg"
    (click)="iconClick()"
  />

  <div
    class="w-430"
    [ngStyle]="{ display: !isShowChatBody ? 'none' : 'block' }"
    [@animate]="{ value: '*', params: { delay: '100ms', x: '25px' } }"
  >
    <div fxLayout="row" fxLayoutAlign="end" class="p-8 custom-chatbox px-12">
      <mat-icon class="s-20 cursor-pointer" (click)="closeIframe()"
        >close</mat-icon
      >
    </div>
    <div
      class="fuse-white-bg boxshadow-sm"
      [ngStyle]="{ display: !isShowChat ? 'none' : 'block' }"
      [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"
    >
      <iframe [src]="iframeUrl" id="main_iframe" title="blackbox"></iframe>
    </div>
  </div>
</div>
