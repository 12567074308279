<div class="inner-scroll p-24" fxLayout="row" fxLayoutAlign="center center">
  <div
    id="pop-up-wrapper"
    fusePerfectScrollbar
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <div id="pop-up-page">
      <div *ngIf="data.showCloseButton" class="s-12">
        <div
          fxLayout="column"
          fxLayoutAlign="end end"
          class="cursor-pointer grey-600-fg"
          (click)="matDialogRef.close('close')"
          aria-label="Close dialog"
        >
          <mat-icon>cancel</mat-icon>
        </div>
      </div>

      <div *ngIf="data.data === 'success'" class="s-100" style="color: #47c399">
        <img
          src="/assets/icons/booking-reservation/check-alert.svg"
          class="mb-8"
          alt=""
        />
        <div class="title border-bottom pb-24">
          {{ data.title }}
        </div>
      </div>
      <div *ngIf="data.data === 'error'" class="s-100" style="color: red">
        <img
          src="/assets/icons/booking-reservation/error-alert.svg"
          class="mb-8"
          alt=""
        />
        <div class="title border-bottom pb-24">
          {{ data.title }}
        </div>
      </div>
      <div *ngIf="data.data === 'warning'" class="s-100" style="color: orange">
        <img
          src="/assets/icons/booking-reservation/warning-alert.svg"
          class="mb-8"
          alt=""
        />
        <div class="title border-bottom pb-24">
          {{ data.title }}
        </div>
      </div>

      <div *ngIf="data.data === 'information'" class="s-100 accent-fg">
        <img
          src="/assets/images/svg/Infopopup.svg"
          class="mb-8 information-logo"
          alt=""
        />
        <div *ngIf="data?.title" class="title border-bottom pb-24">
          {{ data.title }}
        </div>
        <div *ngIf="data?.rebookPartial" class="border-bottom pt-16"></div>
      </div>

      <div
        *ngIf="data.containerProtection && data.details"
        class="description"
        fxLayout="column"
        fxLayoutAlign="center start"
      >
        <table class="text-left w-100-p">
          <tr>
            <td class="w-40-p">Reservation Id:</td>
            <td class="font-weight-900">
              {{ data.details.reservationId }}
            </td>
          </tr>
          <tr>
            <td>Details:</td>
            <td class="font-weight-900">
              {{ data.details.details }}
            </td>
          </tr>
          <tr>
            <td>Action:</td>
            <td class="font-weight-900">
              {{ data.details.action }}
            </td>
          </tr>
        </table>
      </div>

      <div
        *ngIf="data.reservation && !data?.rebookRecommendation"
        class="description"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <table class="text-left">
          <tr>
            <td class="w-30-p">Reservation Id:</td>
            <td class="font-weight-900">
              {{ data.reservationId }}
            </td>
          </tr>
          <tr>
            <td>Details:</td>
            <td class="font-weight-900">
              {{ data.description }}
            </td>
          </tr>
        </table>
      </div>

      <div *ngIf="data.bookTrucking" class="description">
        <table class="text-left w-100-p">
          <tr>
            <td class="w-25-p">Service Id:</td>
            <td class="font-weight-900">
              {{ data.details.serviceId }}
            </td>
          </tr>
          <tr>
            <td>Details:</td>
            <td class="font-weight-900">
              {{ data.details.description }}
            </td>
          </tr>
          <tr>
            <td>Action:</td>
            <td class="font-weight-900">
              {{ data.details.action }}
            </td>
          </tr>
        </table>
      </div>

      <div class="description" *ngIf="!data.reservation">
        <div [innerHtml]="data.description"></div>
      </div>

      <div
        *ngIf="data?.rebookRecommendation && data?.reservation"
        class="description"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <div fxLayout="row" fxLayoutgap="10px" class="w-100-p">
          <div fxLayout="column" fxLayoutalign="end end">
            <div>Details:</div>
          </div>
          <div fxLayout="column" class="text-align" fxLayoutalign="start start">
            <div>
              <strong>{{ data?.rebookDescription }}</strong>
            </div>
            <ng-container *ngIf="data?.containerList">
              <div fxLayout="column" class="py-36">
                <ng-container *ngFor="let container of data.containerList">
                  <strong class="py-4"
                    >{{ container?.containerType }}
                    {{ container?.containerSize }}</strong
                  >
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="data?.containerList2">
              <div fxLayout="column" class="py-36">
                <ng-container *ngFor="let container of data.containerList2">
                  <strong class="py-4">{{ container }}</strong>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="data?.containerList">
              <strong
                >Go 'Back' to edit your container/s or click 'Proceed' to
                continue with the available container/s instead. The latter will
                cancel out the unavailable containers.</strong
              >
            </ng-container>

            <ng-container *ngIf="data?.containerList2">
              <strong
                >Do you wish to cancel this booking instead and find another
                provider?</strong
              >
            </ng-container>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          !data.showSuccess &&
          !data.showWarning &&
          !data.reservation &&
          !data.maybeLaterProceed &&
          !data?.rebookRecommendation &&
          !data?.rebookPartial
        "
      >
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          *ngIf="
            !data.showButtons &&
            !data.showDelete &&
            !data.showInventory &&
            !data.tryAgain &&
            !data.addItem
          "
          (click)="matDialogRef.close('close')"
        >
          CLOSE
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.addItem"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close()"
        >
          No
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="proceed()"
        >
          Proceed
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showButtons"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close()"
        >
          No
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="save()"
        >
          Yes
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showInventory"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close([''])"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close(['delete'])"
        >
          Confirm
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showDelete && !data.trackAndTrace"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close([''])"
        >
          No
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close(['delete'])"
        >
          Yes
        </button>
      </div>
      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.trackAndTrace && data.showDelete"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close([''])"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close(['delete'])"
        >
          Proceed
        </button>
      </div>

      <div
        class="w-100-p"
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="data.containerProtection && data.showSuccess"
      >
        <div class="mr-8">
          <button
            mat-flat-button
            class="maybe grey-600"
            (click)="matDialogRef.close([''])"
          >
            <mat-icon>clear</mat-icon>
            Maybe Later
          </button>
        </div>

        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close(['proceed'])"
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
          Proceed
        </button>
      </div>

      <div
        class="w-100-p"
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="data.bookTrucking && data.showSuccess"
      >
        <div class="mr-8">
          <button
            mat-flat-button
            class="maybe grey-600"
            (click)="matDialogRef.close([''])"
          >
            <mat-icon>clear</mat-icon>
            Maybe Later
          </button>
        </div>

        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close(['proceed'])"
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
          Proceed
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showWarning"
      >
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close(['delete'])"
        >
          Okay
        </button>
      </div>

      <div *ngIf="data.reservation && !data?.rebookRecommendation">
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          *ngIf="!data.showButtons && !data.showDelete"
          (click)="matDialogRef.close('reservation')"
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
          Go to Reservation
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.tryAgain"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close([''])"
        >
          <mat-icon>clear</mat-icon>
          Cancel
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close(['tryAgain'])"
        >
          <mat-icon class="s-24">refresh</mat-icon> Try Again
        </button>
      </div>

      <div
        class="mt-8 bookTrucker"
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="data.maybeLaterProceed && !data?.rebookRecommendation"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close([''])"
        >
          <mat-icon>clear</mat-icon>
          Maybe Later
        </button>

        <button
          mat-flat-button
          color="accent"
          (click)="matDialogRef.close(['proceed'])"
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
          Proceed
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center stretch"
        *ngIf="data?.rebookRecommendation"
      >
        <div class="mr-4">
          <button
            *ngIf="!data?.containerList"
            mat-flat-button
            class="maybe grey-600"
            (click)="matDialogRef.close(['no'])"
          >
            <mat-icon>clear</mat-icon>
            No
          </button>
          <button
            *ngIf="data?.containerList"
            mat-flat-button
            class="maybe grey-600"
            (click)="matDialogRef.close(['no'])"
          >
            Back
          </button>
          <button
            *ngIf="data?.rebookClose"
            mat-flat-button
            class="maybe grey-600"
            (click)="matDialogRef.close(['no'])"
          >
            close
          </button>
        </div>
        <div class="ml-4">
          <button
            mat-flat-button
            class="submit-button w-100-p"
            color="accent"
            (click)="matDialogRef.close(['proceed'])"
          >
            <mat-icon>keyboard_arrow_right</mat-icon>
            Proceed
          </button>
        </div>
      </div>
      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center stretch"
        *ngIf="data?.rebookPartial"
      >
        <div class="mr-4">
          <button
            mat-flat-button
            class="maybe grey-600"
            (click)="matDialogRef.close(['no'])"
          >
            Close
          </button>
        </div>
        <div class="ml-4">
          <button
            mat-flat-button
            class="submit-button w-100-p"
            color="accent"
            (click)="matDialogRef.close(['proceed'])"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
