import { Action } from '@ngrx/store';
import { Booking } from 'app/core/models/booking.model';

export enum ReservationActionTypes {
  SetReservation = '[Reservation] Set Reservation',
  RemoveReservation = '[Reservation] Remove Reservation',
}

export class SetReservation implements Action {
  readonly type = ReservationActionTypes.SetReservation;
  constructor(public payload: Booking) {}
}

export class RemoveReservation implements Action {
  readonly type = ReservationActionTypes.RemoveReservation;
  constructor() {}
}

export type ReservationActions = SetReservation | RemoveReservation;
