import { Action } from '@ngrx/store';
import { Port } from 'app/core/models/port.model';

export enum PortActionTypes {
  SetPorts = '[Port] Set Ports',
  RemovePort = '[Port] Remove Port',
}

export class SetPorts implements Action {
  readonly type = PortActionTypes.SetPorts;
  constructor(public payload: Port[]) {}
}

export class RemovePort implements Action {
  readonly type = PortActionTypes.RemovePort;
  constructor() {}
}

export type PortActions = SetPorts | RemovePort;
