import { CreateRequestBookingSeaFreightInbound } from "app/core/models/booking-sea-freight-inbound.model";
import { BookingSeaFreightInboundActions, BookingSeaFreightInboundActionTypes } from "./sea-freight-inbound.action";

export const initialState: CreateRequestBookingSeaFreightInbound = null;
export function reducer(state = initialState , action: BookingSeaFreightInboundActions ): CreateRequestBookingSeaFreightInbound {
    switch(action.type) {
        case BookingSeaFreightInboundActionTypes.SetBookingSeaFreightInbound:
            state = action.payload;
            return state;
          case BookingSeaFreightInboundActionTypes.RemoveBookingSeaFreightInbound:
            state = initialState;
            return state;
          default:
            return state;
    }

}