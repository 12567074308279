import { Action } from '@ngrx/store';
import { Booking } from 'app/core/models/booking.model';

export enum BookingActionTypes {
  SetBooking = '[Booking] Set Booking',
  RemoveBooking = '[Booking] Remove Booking',
}

export class SetBooking implements Action {
  readonly type = BookingActionTypes.SetBooking;
  constructor(public payload: Booking) {}
}

export class RemoveBooking implements Action {
  readonly type = BookingActionTypes.RemoveBooking;
  constructor() {}
}

export type BookingActions = SetBooking | RemoveBooking;
