<div class="dialog-content-wrapper m-0 p-0" inner-scroll>
  <mat-toolbar class="accent-500 border-radius-bottom-8">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title font-size-16"> {{ data?.title }} </span>
      <div fxLayout="row" fxLayoutGap="8px">
        <button
          mat-button
          class="fuse-white accent-500-fg h-32 border-radius-16 pt-4"
          (click)="addGuestShipper()"
          fxLayout="row"
          fxLayoutAlign="center"
        >
          <mat-icon>add</mat-icon>
          <span>Add Shipper</span>
        </button>
        <div
          fxLayout="column"
          fxLayoutAlign="center"
          class="cursor-pointer"
          (click)="dialogRef.close()"
          aria-label="Close dialog"
        >
          <mat-icon>cancel</mat-icon>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-24 pl-12 pr-12">
    <div fxLayout="row" class="py-8 px-12 border w-100-p border-radius-8">
      <input
        type="text"
        matInput
        placeholder="Search"
        [formControl]="searchFilter"
        fxFlex
      />
      <mat-icon class="color: gray;" matSuffix>search</mat-icon>
    </div>
  </div>

  <div *ngIf="loading">
    <mat-spinner class="m-auto" diameter="35"></mat-spinner>
  </div>
  <div *ngIf="!loading">
    <div class="user-access-container page-layout carded">
      <div class="listingShipper" fxLayout="column" fxLayoutAlign="start">
        <div class="w-100-p py-8 px-8" fxLayout="column">
          <div
            class="select-item p-8 fullwidth w-100-p mat-elevation-z2"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
          >
            <div fxFlex>
              <div class="font-size-10" fxLayout="row" fxLayoutGap="12px">
                <div class="imgBox ml-24" fxLayoutAlign="center center">
                  <!-- <img
                      class="image-size"
                      [src]="company?.imageUrl ? (company?.imageUrl | protectedImage | async) : 'assets/images/logos/without-text/SVG/general.svg' "   
                      onerror="this.src='assets/images/logos/without-text/SVG/general.svg'"
                    /> -->
                  <img
                    class="image-size"
                    [src]="company?.imageUrl"
                    onerror="this.src='assets/images/logos/without-text/SVG/general.svg'"
                  />
                </div>
                <div class="mt-12 ml-12 font-size-29" fxLayout="column">
                  <span class="font-weight-700">
                    {{ company?.companyName }}
                  </span>
                  <span>
                    Registered (Booking Party) <br />
                    {{ company?.city?.cityName }},
                    {{ company?.country?.countryName }}
                  </span>
                </div>
              </div>
            </div>

            <button
              fxFlex
              mat-button
              class="w-100 p-8"
              [ngClass]="{
                'blue-500': !company.isSelected,
                'green-600': company.isSelected
              }"
              (click)="selectShipper(company, 'bpShipper')"
            >
              {{ company?.isSelected ? "Selected" : "Select" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
      <hr
        class="mt-24 mb-24"
        style="
          height: 2px;
          width: 80%;
          border-width: 0;
          background-color: #f0f0f0;
        "
      />
    </div>

    <div class="user-access-container page-layout carded">
      <div class="listingShipper" fxLayout="column" fxLayoutAlign="start">
        <div
          class="w-100-p py-8 px-8"
          fxLayout="column"
          *ngFor="let shipper of shipperList; let i = index"
        >
          <div
            class="select-item p-8 fullwidth w-100-p mat-elevation-z2"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
          >
            <div fxFlex>
              <div class="font-size-10" fxLayout="row" fxLayoutGap="12px">
                <div class="imgBox ml-24" fxLayoutAlign="center center">
                  <!-- <img
                    class="image-size"
                    [src]="
                      shipper.imageUrl
                        ? (shipper.imageUrl | protectedImage | async)
                        : 'assets/images/logos/without-text/SVG/general.svg'
                    "
                    onerror="this.src='assets/images/logos/without-text/SVG/general.svg'"
                  /> -->
                  <img
                    class="image-size"
                    [src]="company?.imageUrl"
                    onerror="this.src='assets/images/logos/without-text/SVG/general.svg'"
                  />
                </div>
                <div class="mt-12 ml-12 font-size-29" fxLayout="column">
                  <span class="font-weight-700">
                    {{ shipper.contactName }}
                  </span>
                  <span>
                    {{ shipper.contactType }} <br />
                    {{ shipper.cityProvince }},
                    {{ shipper.country }}
                  </span>
                </div>
              </div>
            </div>

            <button
              fxFlex
              mat-button
              class="w-100 p-8"
              [ngClass]="{
                'blue-500': !shipper.isSelected,
                'green-600': shipper.isSelected
              }"
              (click)="selectShipper(shipper, 'shipper')"
            >
              {{ shipper?.isSelected ? "Selected" : "Select" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!dataLength" class="text-center py-24 font-size-16 no-results">
      No record found.
    </div>
  </div>

  <mat-paginator
    fxLayout="row"
    fxLayoutAlign="center center"
    #paginator
    [length]="dataLength"
    [pageIndex]="pageNumber"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="pageEvent = $event; handlePage($event)"
  >
  </mat-paginator>
</div>
