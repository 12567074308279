import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  serviceType;
  statusType;

  private bookingData: BehaviorSubject<boolean>;
  private scheduleData: BehaviorSubject<boolean>;
  private containerData: BehaviorSubject<boolean>;
  private containerInventory: BehaviorSubject<boolean>;
  private servicePackageData: BehaviorSubject<boolean>;
  private additionalData: BehaviorSubject<boolean>;
  private disableView: BehaviorSubject<boolean>;
  private setTruckingAmount: BehaviorSubject<boolean>;
  private isCollapsed: BehaviorSubject<boolean>;
  private reccomedationRoutes: BehaviorSubject<boolean>;
  private loadTypeId: BehaviorSubject<boolean>;
  private scheduleRecommendationList: BehaviorSubject<boolean>;
  private scheduleDetails: BehaviorSubject<boolean>;
  private documents: BehaviorSubject<boolean>;
  private lading: BehaviorSubject<boolean>;
  private insurance: BehaviorSubject<boolean>;
  private inboundStepper: BehaviorSubject<boolean>;
  private collectedId: BehaviorSubject<boolean>;
  private completedSchedule: BehaviorSubject<boolean>;
  private resetStepper: BehaviorSubject<boolean>;
  private bookingInbound: BehaviorSubject<boolean>;
  private maybeLater: BehaviorSubject<boolean>;
  private supplementaryTrucking: BehaviorSubject<boolean>;
  private selectedShippingLine: BehaviorSubject<boolean>;
  private selectedShipper: BehaviorSubject<boolean>;
  private currentBookingParty: BehaviorSubject<boolean>;
  private selectedConsignee: BehaviorSubject<boolean>;
  private bookingTotalAmount: BehaviorSubject<boolean>;
  private setRebooking: BehaviorSubject<boolean>;
  private truckingFeeInfo: BehaviorSubject<boolean>;
  private inOtherTrucking: BehaviorSubject<boolean>;
  private otherTrAction: BehaviorSubject<boolean>;
  private blackboxUrl: BehaviorSubject<any>;
  private isShowBB: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private chatsupport: BehaviorSubject<any | null> = new BehaviorSubject(false);
  private outBoundData: BehaviorSubject<boolean>;
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  private refreshing: BehaviorSubject<boolean>;

  constructor() {
    this.bookingData = new BehaviorSubject<boolean>(false);
    this.scheduleData = new BehaviorSubject<boolean>(false);
    this.containerData = new BehaviorSubject<boolean>(false);
    this.containerInventory = new BehaviorSubject<boolean>(false);
    this.servicePackageData = new BehaviorSubject<boolean>(false);
    this.additionalData = new BehaviorSubject<boolean>(false);
    this.disableView = new BehaviorSubject<boolean>(false);
    this.setTruckingAmount = new BehaviorSubject<boolean>(false);
    this.isCollapsed = new BehaviorSubject<boolean>(false);
    this.reccomedationRoutes = new BehaviorSubject(false);
    this.loadTypeId = new BehaviorSubject(false);
    this.scheduleRecommendationList = new BehaviorSubject(false);
    this.scheduleDetails = new BehaviorSubject(false);
    this.documents = new BehaviorSubject(false);
    this.lading = new BehaviorSubject(false);
    this.insurance = new BehaviorSubject(false);
    this.inboundStepper = new BehaviorSubject(false);
    this.collectedId = new BehaviorSubject(false);
    this.completedSchedule = new BehaviorSubject(false);
    this.resetStepper = new BehaviorSubject(false);
    this.bookingInbound = new BehaviorSubject(false);
    this.maybeLater = new BehaviorSubject(false);
    this.supplementaryTrucking = new BehaviorSubject(false);
    this.selectedShippingLine = new BehaviorSubject(false);
    this.selectedShipper = new BehaviorSubject(false);
    this.currentBookingParty = new BehaviorSubject(false);
    this.selectedConsignee = new BehaviorSubject(false);
    this.bookingTotalAmount = new BehaviorSubject(false);
    this.setRebooking = new BehaviorSubject(false);
    this.truckingFeeInfo = new BehaviorSubject(false);
    this.inOtherTrucking = new BehaviorSubject(false);
    this.otherTrAction = new BehaviorSubject(false);
    this.blackboxUrl = new BehaviorSubject(false);
    this.chatsupport = new BehaviorSubject(false);
    this.outBoundData = new BehaviorSubject(false);
    this.refreshing = new BehaviorSubject<boolean>(false);
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  /**
   * Getter
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getTruckingFeeValue(): Observable<boolean> {
    return this.truckingFeeInfo.asObservable();
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */
  setTruckingFeeValue(value): void {
    this.truckingFeeInfo.next(value);
  }

  /**
   * Getter
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getTotalAmount(): Observable<boolean> {
    return this.bookingTotalAmount.asObservable();
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */
  setTotalAmount(value): void {
    this.bookingTotalAmount.next(value);
  }

  /**
   * Getter
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getValue(): Observable<boolean> {
    return this.bookingData.asObservable();
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */
  setValue(value): void {
    this.bookingData.next(value);
  }

  /**
   * Getter
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getValueSchedule(): Observable<boolean> {
    return this.scheduleData.asObservable();
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */
  setValueSchedule(value): void {
    this.scheduleData.next(value);
  }

  /**
   * Getter
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getValueContainer(): Observable<boolean> {
    return this.containerData.asObservable();
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */
  setValueContainer(value): void {
    this.containerData.next(value);
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */
  setPackegeValue(value): void {
    this.servicePackageData.next(value);
  }

  /**
   * Getter
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getPackageValue(): Observable<boolean> {
    return this.servicePackageData.asObservable();
  }
  getValueAdditionalFile(): Observable<boolean> {
    return this.additionalData.asObservable();
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */
  setValueAdditionalFile(value): void {
    this.additionalData.next(value);
  }

  /**
   *
   *
   * @returns {Observable<boolean>}
   * @memberof SharedService
   */
  getDisableView(): Observable<boolean> {
    return this.disableView.asObservable();
  }

  /**
   * Setter
   *
   * @param {*} value
   * @memberof SharedService
   */
  setDisableView(value): void {
    this.disableView.next(value);
  }
  /**
   * @param {*} value
   * @memberof SharedService
   */
  setTotalTruckingAmount(value): void {
    this.setTruckingAmount.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getTotalTruckingAmount(): Observable<boolean> {
    return this.setTruckingAmount.asObservable();
  }

  /**
   * @param {*} value
   * @memberof SharedService
   */
  setIsCollapsed(value): void {
    this.isCollapsed.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getIsCollapsed(): Observable<boolean> {
    return this.isCollapsed.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setReccomedationRoutes(value): void {
    this.reccomedationRoutes.next(value);
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  getReccomedationRoutes(): Observable<boolean> {
    return this.reccomedationRoutes.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setLoadTypeId(value): void {
    this.loadTypeId.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getLoadTypeId(): Observable<boolean> {
    return this.loadTypeId.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setScheduleRecommendationList(value): void {
    this.scheduleRecommendationList.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getScheduleRecommendationList(): Observable<boolean> {
    return this.scheduleRecommendationList.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setScheduleDetails(value): void {
    this.scheduleDetails.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getScheduleDetails(): Observable<boolean> {
    return this.scheduleDetails.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setContainerInventory(value): void {
    this.scheduleDetails.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getContainerInventory(): Observable<boolean> {
    return this.scheduleDetails.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setDocuments(value): void {
    this.documents.next(value);
  }

  /**
   *
   * get service type Id of booking portal and draft Id
   *
   * @returns {Observable<boolean>}
   * @memberof SharedService
   */
  getDocuments(): Observable<boolean> {
    return this.documents.asObservable();
  }

  /**
   */
  getCollectedDraftId(): Observable<boolean> {
    return this.collectedId.asObservable();
  }

  /**
   * set service type Id of booking portal and draft Id
   *
   * @param {*} value
   * @memberof SharedService
   */
  setLading(value): void {
    this.lading.next(value);
  }

  /**
   */
  setCollectedDraftId(value) {
    this.collectedId.next(value);
  }

  /**
   * get service type Id of booking portal and draft Id
   *
   * @returns {Observable<boolean>}
   * @memberof SharedService
   */
  getLading(): Observable<boolean> {
    return this.lading.asObservable();
  }

  /**
   *
   */
  getCompletedSchedule(): Observable<boolean> {
    return this.completedSchedule.asObservable();
  }

  /**
   * set service type Id of booking portal and draft Id
   *
   * @param {*} value
   * @memberof SharedService
   */
  setInsurance(value): void {
    this.insurance.next(value);
  }

  /**
   *
   *
   * @returns {Observable<boolean>}
   * @memberof SharedService
   */
  getInsurance(): Observable<boolean> {
    return this.insurance.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setInboundStepper(value): void {
    this.inboundStepper.next(value);
  }

  /**
   *
   *
   * @returns {Observable<boolean>}
   * @memberof SharedService
   */
  getInboundStepper(): Observable<boolean> {
    return this.inboundStepper.asObservable();
  }

  setCompletedSchedule(value) {
    this.completedSchedule.next(value);
  }

  /**
   * get service type Id of booking portal and draft Id
   *
   * @returns {Observable<boolean>}
   * @memberof SharedService
   */
  getResetStepper(): Observable<boolean> {
    return this.resetStepper.asObservable();
  }

  /**
   * set service type Id of booking portal and draft Id
   *
   * @param {*} value
   * @memberof SharedService
   */
  setResetStepper(value) {
    this.resetStepper.next(value);
  }

  /**
   * set service type Id of booking portal and draft Id
   *
   * @param {*} value
   * @memberof SharedService
   */
  setInboundBooking(value): void {
    this.bookingInbound.next(value);
  }

  /**
   *
   *
   * @returns {Observable<boolean>}
   * @memberof SharedService
   */
  getInboundBooking(): Observable<boolean> {
    return this.bookingInbound.asObservable();
  }

  /**
   * set reminder to boolean when maybe later is clicked
   *
   * @param {*} value
   * @memberof SharedService
   */
  setReminder(value): void {
    this.maybeLater.next(value);
  }

  /**
   *
   *
   * @returns {Observable<boolean>}
   * @memberof SharedService
   */
  getReminder(): Observable<boolean> {
    return this.maybeLater.asObservable();
  }

  /**
   * set reminder to boolean when maybe later is clicked
   *
   * @param {*} value
   * @memberof SharedService
   */
  setSupplementaryTrucking(value): void {
    this.supplementaryTrucking.next(value);
  }

  /**
   *
   *
   * @returns {Observable<boolean>}
   * @memberof SharedService
   */
  getSupplementaryTrucking(): Observable<boolean> {
    return this.supplementaryTrucking.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setSelectedShippingLine(value): void {
    this.selectedShippingLine.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getSelectedShippingLine(): Observable<boolean> {
    return this.selectedShippingLine.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setSelectedShipper(value): void {
    this.selectedShipper.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getSelectedShipper(): Observable<boolean> {
    return this.selectedShipper.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setBookingParty(value): void {
    this.currentBookingParty.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getBookingParty(): Observable<boolean> {
    return this.currentBookingParty.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setSelectedConsignee(value): void {
    this.selectedConsignee.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getSelectedConsignee(): Observable<boolean> {
    return this.selectedConsignee.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setReookingData(value): void {
    this.setRebooking.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getRebookingData(): Observable<boolean> {
    return this.setRebooking.asObservable();
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setInOtherTrucking(value): void {
    this.inOtherTrucking.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getInOtherTrucking(): Observable<boolean> {
    return this.inOtherTrucking.asObservable();
  }

  get getBlackBoxUrl(): Observable<any> {
    return this.blackboxUrl.asObservable();
  }

  setBlackBoxUrl(value) {
    this.blackboxUrl.next(value);
  }

  get chatSupport(): Observable<any> {
    return this.chatsupport.asObservable();
  }

  setChatSupport(value) {
    this.chatsupport.next(value);
  }

  get showBB(): Observable<any> {
    return this.isShowBB.asObservable();
  }

  setshowBB(value) {
    this.isShowBB.next(value);
  }

  getActionOtherTr(): Observable<boolean> {
    return this.otherTrAction.asObservable();
  }

  setActionOtherTr(value): void {
    this.otherTrAction.next(value);
  }

  /**
   *
   *
   * @param {*} value
   * @memberof SharedService
   */
  setOutBoundData(value): void {
    this.outBoundData.next(value);
  }

  /**
   *
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SharedService
   */
  getOutBoundData(): Observable<boolean> {
    return this.outBoundData.asObservable();
  }

  setRefresh(value): void {
    this.refreshing.next(value);
  }

  get refresh(): Observable<boolean> {
    return this.refreshing.asObservable();
  }
}
