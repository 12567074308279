import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UploaderService } from "app/services/uploader/uploader.service";

@Pipe({
  name: "protectedImage",
})
export class ProtectedImagePipe implements PipeTransform {
  constructor(
    private _sharedService: UploaderService,
    private sanitizer: DomSanitizer
  ) {}

  async transform(value: string, arg1?: string) {
    if (value) {
      if (value.includes("cy-storage")) {
        return new Promise((resolve, reject) => {
          let payload = value.split("?");
          this._sharedService
            .getCyImage(decodeURI(payload[0]))
            .subscribe((res) => {
              if (res) {
                console.log(res);
                resolve(res.data as string);
              }
            });
        });
      } else if (value.includes("storage")) {
        let payload = value.split("?");
        return new Promise((resolve, reject) => {
          this._sharedService.getClientImage(payload[0]).subscribe((res) => {
            if (res) {
              resolve(res.data && (res.data.presigneUrl as string));
            }
          });
        });
      } else if (value.includes("document")) {
        return new Promise((resolve, reject) => {
          this._sharedService
            .getTransactionImage(value, arg1)
            .subscribe((res) => {
              if (res) {
                resolve(res.data && (res.data.presigneUrl as string));
              }
            });
        });
      } else {
        return value;
      }
    }
  }
}
