import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent {
    public confirmMessage: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>
    ) {
        if (_data) {
            this.confirmMessage = _data.message;
        }
    }

}
