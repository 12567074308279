import { Action } from '@ngrx/store';
import { BookingTrucking, CreateRequestBookingTrucking } from 'app/core/models/booking-trucking.models';

export enum BookingTruckingActionTypes {
  SetBookingTrucking = '[BookingTrucking] Set Booking Trucking',
  RemoveBookingTrucking = '[BookingTrucking] Remove Booking Trucking',
}

export class SetBookingTrucking implements Action {
  readonly type = BookingTruckingActionTypes.SetBookingTrucking;
  constructor(public payload: CreateRequestBookingTrucking) {}
}

export class RemoveBookingTrucking implements Action {
  readonly type = BookingTruckingActionTypes.RemoveBookingTrucking;
  constructor() {}
}

export type BookingTruckingActions = SetBookingTrucking | RemoveBookingTrucking;
