import { Component, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RootState, selectUser } from 'app/core/store';
import { SetCompany } from 'app/core/store/company/company.action';
import { SetPorts } from 'app/core/store/port/port.action';
import { SetProfile } from 'app/core/store/profile/profile.action';
import { SetUser } from 'app/core/store/user/user.action';
import { TokenService } from 'app/services/token/token.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent {
    company: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */
    constructor(
        private storage: Store<RootState>,
        private tokenService: TokenService,
    ) {
        this._unsubscribeAll = new Subject();
        // Set the defaults
        this.storage
            .pipe(select(selectUser))
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                if (res) {
                    this.company = res.company;
                }
            });
    }

    logout() {
        this.tokenService.setToken({});
        this.storage.dispatch(new SetCompany(null));
        this.storage.dispatch(new SetUser(null));
        this.storage.dispatch(new SetProfile(null));
        this.storage.dispatch(new SetPorts(null));
        localStorage.removeItem('token');
        localStorage.removeItem("refreshToken");
        location.replace(environment.loginUrl);
    }
}
