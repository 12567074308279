<mat-toolbar class="accent-500 border-radius-bottom-8">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
    <span class="font-weight-900 font-size-16"
      >Select Type Of
      {{
        type == 0 ? 'Shipper' : type == 1 ? 'Consignee' : 'Notify Party'
      }}</span
    >
    <div
      fxLayout="column"
      fxLayoutAlign="center"
      class="cursor-pointer"
      (click)="dialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>cancel</mat-icon>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content class="mat-typography">
  <div
    fxLayout="column"
    fxLayoutAlign="center"
    fxLayoutGap="12px"
    class="py-52 px-80"
  >
    <button
      mat-button
      class="accent-500 button-shipper"
      (click)="openSelectShipper()"
    >
      <mat-icon>
        check_circle_outline
      </mat-icon>
      Registered
      {{ type == 0 ? 'Shipper' : type == 1 ? 'Consignee' : 'Notify Party' }}
    </button>
    <button
      mat-button
      class="accent-500 button-shipper"
      (click)="openGuestShipper()"
    >
      Guest
      {{ type == 0 ? 'Shipper' : type == 1 ? 'Consignee' : 'Notify Party' }}
    </button>
  </div>
</mat-dialog-content>
