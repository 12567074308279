<div fxLayout="column" class="dialog-content-wrapper m-0 p-0">
  <div fxLayout="row" fxLayoutAlign="start">
    <mat-toolbar matDialogTitle class="accent">
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
        <div id="countdown">
          <div id="countdown-number">{{ timeLeft | number: "2.0" }}</div>
          <svg width="160" height="160">
            <circle id="circle" r="18" cx="20" cy="20"></circle>
          </svg>
        </div>
        <span class="dialog-title">{{ data.title }} </span>
        <div
          class="cursor-pointer"
          (click)="matDialogRef.close()"
          aria-label="Close dialog"
        >
          <mat-icon>cancel</mat-icon>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
  <div class="content">
    <div fxLayout="column" fxLayoutAlign="start" class="p-24">
      <div class="info-line" fxLayout="column">
        <div class="info font-size-10 text-uppercase">Trip Status</div>
        <div
          class="info text-uppercase font-weight-900"
          [ngClass]="{
            'amber-fg': details.TripStatus === 'ONGOING',
            'xlog-fg': details.TripStatus !== 'ONGOING'
          }"
        >
          {{ details.TripStatus }}
        </div>
      </div>
      <div class="info-line" fxLayout="column">
        <div class="info font-size-10 text-uppercase">
          reservation id/Service Id
        </div>
        <div class="title text-uppercase">
          {{ details.ReservationId }} > {{ details.ServiceTicketId }}
        </div>
      </div>
      <div class="info-line" fxLayout="column">
        <div class="info font-size-10 text-uppercase">Job Order/Trip No</div>
        <div class="title">{{ details.JobOrderNo }} - {{ details.TripNo }}</div>
      </div>
      <div class="info-line" fxLayout="column">
        <div class="info font-size-10 text-uppercase">Company:</div>
        <div class="title">
          {{ details.TruckingCompanyName }}
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxLayoutAlign="center center" class="p-24">
          <button mat-button class="accent" (click)="matDialogRef.close()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
