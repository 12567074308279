import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingService } from 'app/services/booking/booking.service';
import { fuseAnimations } from '@fuse/animations';
import { PopUpComponent } from 'app/layout/components/pop-up/pop-up.component';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { RootState, selectCompany, selectUser } from 'app/core/store';
import { Subject } from 'rxjs';
import { SelectTypeConsigneeDialogComponent } from '../select-type-consignee-dialog/select-type-consignee-dialog.component';
import { SelectTypeShipperDialogComponent } from '../select-type-shipper-dialog/select-type-shipper-dialog.component';

@Component({
  selector: 'app-select-consignee-dialog',
  templateUrl: './select-consignee-dialog.component.html',
  styleUrls: ['./select-consignee-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class SelectConsigneeDialogComponent implements OnInit {

  consigneeList: any[];
  selectedConsignee: any;
  showButton = false;
  loading: boolean = false;

  searchFilter = new FormControl();
  filteredValues = '';

  dataLength = 0;
  pageNumber = 0;
  pageSize = 10;
  pageEvent: PageEvent;

  data: any;
  company: any;

  constructor(
    public dialogRef: MatDialogRef<SelectTypeConsigneeDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) _data: any,
    private bookingService: BookingService,
    private store: Store<RootState>,
  ) {

    if (_data) {
      this.data = _data;
      this.company = this.data?.company;
    }
    this.getConsigneeContacts(this.filteredValues);
    this.loading = true;
  }

  ngOnInit(): void {
    this.searchFilter.valueChanges
      .pipe(debounceTime(600))
      .pipe(distinctUntilChanged())
      .subscribe((searchFilter) => {
        this.filteredValues = searchFilter;
        this.pageNumber = 0;
        this.getConsigneeContacts(this.filteredValues)
      })
  }

  /**
   * Paginator
   *
   * @param {*} event
   * @memberof SelectShipperDialogComponent
   */
  public handlePage(event) {
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getConsigneeContacts(this.filteredValues);
  }

  checkSelected() {
    let currentConsignee = this.data.selectedConsignee;
    if (currentConsignee === null) {
      this.company["isSelected"] = false;
      this.consigneeList.forEach(element => {
        element.isSelected = false;
      });
    } else {
      if (this.data.selectedConsignee) {
        let selectedConsignee = this.data.selectedConsignee
        this.consigneeList.forEach(element => {
          if (element.contactId === selectedConsignee.contactId) {
            element.isSelected = true;
          } else {
            element.isSelected = false;
          }
        });

        if (this.company.companyId === selectedConsignee.contactId) {
          this.company["isSelected"] = true;
        } else {
          this.company["isSelected"] = false;
        }
      }
    }

  }

  selectConsignee(consignee, type): void {
    if (type === "consignee") {
      this.consigneeList.forEach(element => {
        element.isSelected = false;
      });
      consignee.isSelected = true;

      this.dialogRef.close({
        consigneeList: this.consigneeList,
        consignee
      });
    } else {
      consignee.isSelected = true;
      let p = consignee && consignee.phone
      let phoneNumber = p.phonePrefix + p.phone;

      let bpConsignee = {
        cityProvince: consignee && consignee.city && consignee.city.cityName,
        contactId: consignee && consignee.companyId,
        contactName: consignee && consignee.companyName,
        country: consignee && consignee.country && consignee.country.countryName,
        email: consignee && consignee.emailAddress,
        phoneNumber: phoneNumber,
        imageUrl: consignee && consignee.imageUrl
      }

      consignee = bpConsignee;

      this.dialogRef.close({
        consigneeList: this.consigneeList,
        consignee
      });
    }
  }

  showTypeConsignee(): void {
    this.dialogRef.close();
    this.dialog.open(SelectTypeShipperDialogComponent, {
      width: '500px',
      data: {
        type: 1
      }
    });
  }

  /**
   *
   *
   * @memberof SelectConsigneeDialogComponent
   */
  openPopUp(data) {
    this.dialogRef.close(SelectConsigneeDialogComponent);
    return this.dialog.open(PopUpComponent, {
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
        showDelete: data.showDelete,
      },
    });
  }

  getConsigneeContacts(search) {
    this.bookingService.getContactsInbound(search, this.pageNumber, this.pageSize, 1).subscribe(res => {
      if (res) {
        this.loading = false;
        const { pagedResponse } = res.data;
        this.consigneeList = pagedResponse.map(element => {
          return {
            ...element,
            contactType: element && element.contactType === 1 ? "Registered" : "Guest" || '',
            isSelected: false,
          }
        })

        this.checkSelected();
        this.pageNumber = res?.data?.pageNumber;
        this.pageSize = res?.data?.pageSize;
        this.dataLength = res?.data?.totalRecords;

      }
    }, error => {
      console.warn(error);
      this.openPopUp(
        {
          data: 'error',
          title: 'Error',
          description: error
        }
      );
    }
    )

  }
}
