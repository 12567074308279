import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProtectedImagePipe } from './protected-image.pipe';
import { SafePipe } from './safe.pipe';



@NgModule({
  declarations: [ProtectedImagePipe, SafePipe],
  imports: [
    CommonModule
  ],
  exports: [
    ProtectedImagePipe,
    SafePipe
  ]
})
export class ProtectedImageModule { }
