import { ReservationActions, ReservationActionTypes } from './reservation.action';
import { Booking } from '../../models/booking.model';

export const initialState: Booking = null;
export function reducer(state = initialState, action: ReservationActions): Booking {
  switch (action.type) {
    case ReservationActionTypes.SetReservation:
      state = action.payload;
      return state;
    case ReservationActionTypes.RemoveReservation:
      state = initialState;
      return state;
    default:
      return state;
  }
}
