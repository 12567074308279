
import { ContainerList } from "app/main/apps/container-management/component/container-management.type";
import { ContainerActions, ContainerActionTypes } from "./container.action";

export const initialState: ContainerList = null;
export function reducer(state = initialState, action: ContainerActions): ContainerList {
    switch (action.type) {
        case ContainerActionTypes.SetContainer:
            state = action.payload;
            return state;
        case ContainerActionTypes.RemoveContainer:
            state = initialState;
            return state;
        default:
            return state;
    }
}
