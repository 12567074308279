import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { Store } from "@ngrx/store";
import { RootState } from "app/core/store";
import { Subject } from "rxjs";

@Component({
  selector: "app-select-trucking-type-dialog",
  templateUrl: "./select-trucking-type-dialog.component.html",
  styleUrls: ["./select-trucking-type-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class SelectTruckingTypeDialogComponent implements OnInit {
  data: any;

  private destroyed$ = new Subject();
  constructor(
    public matDialogRef: MatDialogRef<SelectTruckingTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _fuseSplashScreenService: FuseSplashScreenService,
    public dialog: MatDialog,
    private store: Store<RootState>,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.data = _data;
  }

  navigateTo(url) {
    if (url == "manual") {
      this.matDialogRef.close();
      this.router.navigate(
        [
          `/main/booking-portal/booking-request/preferred-trucking/${this.data.serviceTypeId}`,
        ],
        { replaceUrl: true }
      );
    } else {
      this.matDialogRef.close();
      this.router.navigate(
        [
          `/main/booking-portal/booking-request/trucking/${this.data.serviceTypeId}`,
        ],
        { replaceUrl: true }
      );
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
