import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { PopUpComponent } from 'app/layout/components/pop-up/pop-up.component';
import { BookingService } from 'app/services/booking/booking.service';
import { forEach } from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SelectTypeShipperDialogComponent } from '../select-type-shipper-dialog/select-type-shipper-dialog.component';

@Component({
  selector: 'app-select-notify-party-dialog',
  templateUrl: './select-notify-party-dialog.component.html',
  styleUrls: ['./select-notify-party-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SelectNotifyPartyDialogComponent implements OnInit {
  data: any

  notifyPartyList: any[]
  multipleSelectedNotifyParty: any[] = []
  selectedNotifyParty: any[] = [];
  payload = 5;
  loading: boolean = false;

  shipper: any;
  consignee: any;
  notifyParty: any;

  searchFilter = new FormControl();
  filteredValues = '';
  filteredArray = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  hr: boolean = false;
  dataLength = 0;
  pageNumber = 0;
  pageSize = 10;
  pageEvent: PageEvent;

  count = 0;
  company: any;
  selectCompany = [];
  unselectCompany = [];
  resData: any;
  existing: boolean = false;

  constructor(
    public matDialogRef: MatDialogRef<SelectNotifyPartyDialogComponent>,
    private dialog: MatDialog,
    private bookingService: BookingService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.loading = true;
    this.data = _data
    if (this.data) {
      this.company = this.data?.company;
      if (this.data?.shipper) {
        this.shipper = this.data?.shipper;
      }
      if (this.data?.consignee) {
        this.consignee = this.data?.consignee;
      }
      if (this.data?.notifyParty) {
        this.notifyParty = this.data?.notifyParty;
      }
    }
    this.getNotifyPartyContacts(this.filteredArray);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.notifyParty.forEach((results) => {
        const result = this.filteredArray.find((c) => (c.contactId === results.contactId))
        if (result) {
          result.isSelected = true
          this.multipleSelectedNotifyParty.push(result)
          this.hr = true
        }
      })
    }, 500)

    this.searchFilter.valueChanges
      .pipe(debounceTime(600))
      .pipe(distinctUntilChanged())
      .subscribe((searchFilter) => {
        this.filteredValues = searchFilter;
        this.pageNumber = 0;
        this.getNotifyPartyContacts(this.filteredValues)

        setTimeout(() => {

          if (this.filteredArray.length > 0) {
            this.filteredArray.forEach((element) => {
              if (element.isSelected == true) {
                this.hr = false
              } else {
                this.hr = true
              }
            })
          }
        }, 500)

      })
  }

  searchFiltering() {
    this.filteredArray = [];
    this.loading = false;
    this.notifyPartyList.forEach((element) => {
      let contactName = element?.contactName?.toLowerCase();

      if (contactName.includes((this.filteredValues))) {
        this.filteredArray.push(element)
      }

    })
  }

  selectNotifyParty(index: number): void {
    this.hr = true;
    this.filteredArray[index].isSelected = !this.filteredArray[index]
      .isSelected;
    if (this.filteredArray[index].isSelected == true) {
      this.multipleSelectedNotifyParty.push(this.filteredArray[index]);
      this.filteredArray.splice(index, 1);
    }
  }

  unselectedNotifyParty(index: number): void {
    this.multipleSelectedNotifyParty[index].isSelected = !this.multipleSelectedNotifyParty[index].isSelected;

    const result = this.filteredArray.find((c) => (c.contactId === this.multipleSelectedNotifyParty[index].contactId))

    if (result) {
      setTimeout(() => {
        this.filteredArray.forEach((res, key) => {
          if (result.contactId === res.contactId) {
            this.filteredArray.splice(0, 1);
          }
        })
      }, 500)
    }

    this.filteredArray.unshift(this.multipleSelectedNotifyParty[index]);

    this.multipleSelectedNotifyParty.splice(index, 1);

    if (this.multipleSelectedNotifyParty.length == 0) {
      this.hr = false;
    }

  }

  addNotifyParty() {
    if (this.unselectCompany.length !== 0) {
      this.multipleSelectedNotifyParty.unshift(this.unselectCompany[0])
      this.unselectCompany.splice(0, 1)
    }
    this.matDialogRef.close(['save', this.multipleSelectedNotifyParty]);
  }

  getNotifyPartyContacts(filteredValues) {
    this.bookingService.getContactsInbound(filteredValues, this.pageNumber, this.pageSize, 1).subscribe(res => {
      if (res) {
        this.count++;
        const { pagedResponse, totalRecords, pageSize, pageNumber } = res.data;
        this.filteredArray = pagedResponse.map(element => {
          return {
            ...element,
            contactType: element && element.contactType === 1 ? "Registered" : "Guest" || '',
            isSelected: false,
          }
        })
        setTimeout(() => {
          this.loading = false;
        }, 1000)


        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
        this.dataLength = totalRecords;
        if (this.count === 1) {
          this.resData = this.filteredArray;
          this.checkBookingParty();
        }


        if (this.multipleSelectedNotifyParty.length > 0) {
          this.multipleSelectedNotifyParty.forEach((res) => {
            const result = this.filteredArray.find((c) => (c.contactId === res.contactId))
            if (result) {
              result.isSelected = true
              this.hr = true
              if (result.contactId === res.contactId) {
                return false
              } else {
                this.multipleSelectedNotifyParty.push(res)
              }
            }
          })
        }

      }
    })
  }

  /**
 * Paginator
 *
 * @param {*} event
 * @memberof SelectShipperDialogComponent
 */
  public handlePage(event) {
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getNotifyPartyContacts(this.filteredValues);
  }


  selectedContact(contact) {
    let contactIndex = this.filteredArray.findIndex((element) => element.preferredContactId == contact?.preferredContactId)
    this.selectNotifyParty(contactIndex);
  }

  showTypeNotifyParty(): void {
    this.matDialogRef.close();
    this.dialog.open(SelectTypeShipperDialogComponent, {
      width: '500px',
      data: {
        type: 2,
      },
    });
  }

  /**
   *
   *
   * @memberof SelectNotifyPartyDialogComponent
   */
  openPopUp(data) {
    this.matDialogRef.close(SelectNotifyPartyDialogComponent);
    return this.dialog.open(PopUpComponent, {
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
        showDelete: data.showDelete,
      },
    });
  }


  // ======================================================= //
  // ||                                                   || //
  // ||              Booking Party Selection              || //
  // ||                                                   || //
  // ======================================================= //


  selectedCompany(i) {
    this.selectCompany[i].isSelected = true;
    if (this.selectCompany[i].isSelected == true) {
      this.unselectCompany.push(this.selectCompany[i]);
      this.selectCompany.splice(i, 1);
    }
  }

  unselectedCompany(i) {
    this.unselectCompany[i].isSelected = false;
    if (this.unselectCompany[i].isSelected == false) {
      this.selectCompany.push(this.unselectCompany[i]);
      this.unselectCompany.splice(i, 1);
    }
  }

  checkBookingParty() {
    this.selectCompany = [];
    let np = this.company
    let p = np && np.phone
    let phoneNumber = p.phonePrefix + p.phone;

    let bpNotifyParty = {
      cityProvince: np && np.city && np.city.cityName,
      contactId: np && np.companyId,
      contactName: np && np.companyName,
      contactType: 'Registered',
      country: np && np.country && np.country.countryName,
      email: np && np.emailAddress,
      phoneNumber: phoneNumber,
      imageUrl: np && np.imageUrl,
      isSelected: false
    }

    if (this.notifyParty.length !== 0) {
      let found = this.notifyParty.findIndex((element) => element.contactId === bpNotifyParty.contactId)

      if (found !== -1) {
        this.notifyParty[found]["isSelected"] = true;
        bpNotifyParty["isSelected"] = true;
        this.unselectCompany.push(bpNotifyParty)
      } else {
        bpNotifyParty["isSelected"] = false;
        this.selectCompany.push(bpNotifyParty)
      }
    } else {
      bpNotifyParty["isSelected"] = false;
      this.selectCompany.push(bpNotifyParty)
    }
  }

  companyStatus(company): boolean {
    return company.isSelected;
  }

  /**
   * Checks notify party list if booking party already exist
   * If bp is existing, client side made company details
   * will not be displayed
   *
   * @return {*} 
   * @memberof SelectNotifyPartyDialogComponent
   */
  duplicateChecker() {
    this.resData.forEach((element) => {
      if (this.company) {
        if (element.contactId === this.company.companyId) {
          this.existing = true;
          this.company = [];
          this.selectCompany = [];
          this.unselectCompany = [];
        }
      }
    })
    return !this.existing;
  }

}
