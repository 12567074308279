<div class="inner-scroll" fxLayout="row" fxFlex="100%">
  <div
    id="select-modal"
    fxFlex="1 0 auto"
    fusePerfectScrollbar
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <div
      id="select-pop-up-page"
      fxLayout="column"
      fxLayoutAlign="space-between start"
      fxFlex="1 0  auto"
      class="pb-24"
    >
      <mat-toolbar class="accent">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
          <span class="title dialog-title h3 font-weight-800 font-size-14">
            Book Trucking
          </span>
          <div
            class="cursor-pointer"
            (click)="matDialogRef.close()"
            aria-label="Close dialog"
          >
            <mat-icon>cancel</mat-icon>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <div
        class="p-8 w-100-p"
        fxLayout="column"
        fxLayoutAlign="space-between start"
        fxFlex="1 0 auto"
        fxLayoutGap="4"
      >
        <div
          fxFlex
          class="select-item p-4 fullwidth w-100-p mat-elevation-z2 my-4"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          [@animate]="{
            value: '*',
            params: { delay: '300ms', x: '100%' }
          }"
        >
          <div fxLayout="row" fxLayoutGap="4px">
            <span class="mt-6 ml-6 pl-12 font-size-29 font-weight-700">
              Manual Declare
            </span>
          </div>
          <button
            fxFlex
            mat-button
            class="w-100 p-4 accent"
            (click)="navigateTo('manual')"
          >
            Select
          </button>
        </div>

        <div
          fxFlex
          class="select-item p-4 fullwidth w-100-p mat-elevation-z2 my-4"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          [@animate]="{
            value: '*',
            params: { delay: '300ms', x: '100%' }
          }"
        >
          <div fxLayout="row" fxLayoutGap="4px">
            <span class="mt-6 ml-6 pl-12 font-size-29 font-weight-700">
              On-Demand
            </span>
          </div>
          <button
            fxFlex
            mat-button
            class="w-100 p-4 accent"
            (click)="navigateTo('on-demand')"
          >
            Select
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
