import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SelectTypeShipperDialogComponent } from '../select-type-shipper-dialog/select-type-shipper-dialog.component';
import { SelectShipperDialogComponent } from '../select-shipper-dialog/select-shipper-dialog.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { RootState, selectUser, selectCompany } from 'app/core/store';
import { User } from 'app/core/models/user.model';
import { take, takeUntil } from 'rxjs/operators';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { BookingService } from 'app/services/booking/booking.service';
import { PopUpComponent } from 'app/layout/components/pop-up/pop-up.component';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { ContactsService } from 'app/services/contacts/contacts.service';
import { CountryService } from 'app/services/country/country.service';
import { UploaderService } from 'app/services/uploader/uploader.service';
import { SnackbarComponent } from '@fuse/components/snackbar/snackbar.component';
import { FuseUtils } from '@fuse/utils';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-guest-shipper-dialog',
  templateUrl: './guest-shipper-dialog.component.html',
  styleUrls: ['./guest-shipper-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class GuestShipperDialogComponent implements OnInit {
  profile: User;
  guestDetailsForm: FormGroup;
  submitted: boolean;
  image: any;
  imageFile: any;
  shipperList: any[];
  type: number;

  countries: any = [];
  callingCodes: any = [];
  states: any = [];
  cities: any = [];

  selectedCountry: any;
  selectedStates: any;

  services: any = [];

  otherServices: any = [];
  shipperServices: any = [];

  maxSize: boolean = false;
  formatPicture: boolean = false;

  saveImageCompany: any;

  hasUploaded: boolean;
  // submitted = false;
  Image: any;
  saveImageUser: any;

  company: any;
  private destroyed$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<GuestShipperDialogComponent>,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private store: Store<RootState>,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private uploader: UploaderService,
    private bookingService: BookingService,
    private countryService: CountryService,
    @Inject(MAT_DIALOG_DATA) _data: any,
    private contactService: ContactsService,
    private _snackBar: MatSnackBar
  ) {
    this.dialogRef.disableClose = true;
    this.store
      .pipe(take(1))
      .pipe(select(selectUser))
      .subscribe((profile) => {
        this.profile = profile;
      });

    if (_data) {
      this.type = _data.type;
    }
    this.store
      .pipe(select(selectCompany))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.company = res;

          this.countryService
            .getInitialInfo()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((content) => {
              ({
                countries: this.countries,
                callingCodes: this.callingCodes,
                otherServices: this.otherServices,
                shipperServices: this.shipperServices,
              } = content && content.data && content.data.info);

              this.countries = this.countries.map((country) => {
                return {
                  countryId: country.countryId,
                  countryName: country.name,
                };
              });

              this.company.CountryId = this.countries.find(
                (c) => c.countryId === this.company.CountryId
              );
              this.company.CountryName = this.countries.find(
                (c) => c.countryName === this.company.CountryName
              );
            });
        },
        (err) => {
          this._fuseSplashScreenService.hide();
          console.warn(err);
        }
      );
  }

  ngOnInit(): void {
    this.guestDetailsForm = this._formBuilder.group({
      GuestName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      FirstName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      LastName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      EmailAddress: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.email,]],
      PhoneNumber: ['', [Validators.required, Validators.minLength(5), Validators.pattern('^[0-9]+$'), Validators.maxLength(15)]],
      PhoneNumberPrefix: ['', Validators.required],
      FaxNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.pattern('^[0-9]+$'),
          Validators.maxLength(15),
        ],
      ],
      FaxNumberPrefix: ['', Validators.required],
      MobileNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.pattern('^[0-9]+$'),
          Validators.maxLength(15),
        ],
      ],
      MobileNumberPrefix: ['', Validators.required],
      AddressLine: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(255),
        ],
      ],
      CountryName: ['', Validators.required],
      StateName: [{ value: '', disabled: true }, Validators.required],
      CityName: [{ value: '', disabled: true }, Validators.required],
      Image: ['', Validators.required],
      ZipCode: ['', [Validators.required, Validators.min(1), Validators.pattern('^[a-zA-Z0-9]+$'), Validators.maxLength(10)]],
      //addAsGuestShipper: [false],
    });
  }

  openSelectTypeShipper(): void {
    this.dialogRef.close();
    this.dialog.open(SelectTypeShipperDialogComponent, {
      autoFocus: null,
      width: '500px',
    });
  }

  openSelectShipper(shipperList): void {
    this.dialogRef.close();
    this.dialog.open(SelectShipperDialogComponent, {
      autoFocus: null,
      width: '500px',
      data: {
        shipperList,
      },
    });
  }

  openPopUp(message, title, description): void {
    this.dialogRef.close();
    this.dialog.open(PopUpComponent, {
      width: '400px',
      panelClass: 'contact-form-dialog',
      data: {
        data: message,
        title,
        description,
      },
    });
  }

  async proceedAddShipper(): Promise<void> {
    this.submitted = true;
    if (this.guestDetailsForm.invalid) {
      return;
    }

    const addAsGuestShipper = this.guestDetailsForm.get('addAsGuestShipper')
      .value;

    this._fuseSplashScreenService.show();
    const data = this.guestDetailsForm.getRawValue();

    delete data.image;
    delete data.addAsGuestShipper;

    let image;
    await new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', this.imageFile);

      this.uploader
        .singleUploadImage(formData)
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            image = res.data;
            resolve(res.data);
          },
          (error) => {
            this._fuseSplashScreenService.hide();
            reject(error);
          }
        );
    });

    const company = {
      ...data,
      guestType: this.type,
      profileId: this.profile.user.userId,
      companyId: this.profile.company.companyId,
      image,
    };

    this.bookingService.addGuestShipper(company).subscribe(
      (res) => {
        this._fuseSplashScreenService.hide();
        this.openPopUp('success', 'SUCCESS !', 'Added Successfully');
      },
      (err) => {
        this.openPopUp('error', 'ERROR !', 'Error adding guest shipper');
      }
    );
  }

  setImage(files): void {
    const reader = new FileReader();
    reader.readAsDataURL(files.target.files[0]);
    reader.onload = (_event) => {
      this.image = reader.result;
      this.imageFile = files.target.files[0];
    };
  }

  close(): void {
    this.dialogRef.close();
  }

  findCallingCode(id) {
    return this.callingCodes.find((element) => element.countryId === id);
  }

  selectedCountryData(e) {
    if (e.isUserInput) {
      let country = this.findCallingCode(e.source.value.countryId);
      let companyImage = this.Image;

      this.states = [];
      this.cities = [];

      this.guestDetailsForm.controls['StateName'].enable();

      this.patchValueForm({
        MobileNumberPrefix: country && country.countryId,
        PhoneNumberPrefix: country && country.countryId,
        FaxNumberPrefix: country && country.countryId,
      });

      this.Image = companyImage;
      this.getState(e.source.value.countryId);
      this.selectedCountry = e.source.value;
    }
  }

  selectedStateData(e) {
    if (e.isUserInput) {
      this.selectedStates = e.source.value;
      this.guestDetailsForm.controls['CityName'].enable();
      this.getCities(e.source.value.stateId);
    }
  }

  getState(countryId) {
    this.countryService
      .getStates(countryId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.states = res.data.states.map((element) => {
          return {
            stateName: element.name,
            stateId: element.stateId,
          };
        });
        if (this.states.length) {
          let res = this.states.find((c) => c.stateId === this.company.StateId);
          this.company.StateId = res;
          this.patchValueForm({
            StateName: this.company.StateName,
          });
        }
      });
  }

  getCities(stateId) {
    this.countryService
      .getCities(stateId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.cities = res.data.cities.map((element) => {
          return {
            cityName: element.name,
            cityId: element.cityId,
          };
        });
        if (this.cities.length) {
          let res = this.cities.find((c) => c.cityId === this.company.CityId);
          this.company.CityId = res.cityId;
          this.patchValueForm({
            city: this.company.CityName,
          });
        }
      });
  }

  patchValueForm(data) {
    // this.companyImage = this.company.imageUrl;
    this.guestDetailsForm.patchValue({
      ...data,
    });
  }

  validateImagesCompany(files): void {
    const file = files.addedFiles[0];
    const fileSize = file.size / 1024 / 1024;
    const fileType = file.type;
    const formatImages = 'image/jpeg|image/png|image/jpg';
    // if  > 5 MB
    // Convert Decimal
    if (fileSize >= 5.24288) {
      this.maxSize = true;
      this.formatPicture = false;
      this.Image = '';
    } else if (!fileType.match(formatImages)) {
      // PNG, JPEG, JPG ONLY
      this.formatPicture = true;
      this.maxSize = false;
      this.Image = null;
    } else {
      // PROCEED TO SHOW
      const reader = new FileReader();
      reader.readAsDataURL(files.addedFiles[0]);
      reader.onload = (_event) => {
        this.Image = reader.result;
        this.guestDetailsForm.controls.Image.setValue(this.Image);
        this.saveImageUser = files.addedFiles[0];
        this.maxSize = false;
        this.formatPicture = false;
      };
    }
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async submitNewUser() {
    this._fuseSplashScreenService.show();
    const data = this.guestDetailsForm.getRawValue();

    const mobilePrefix = this.callingCodes.find(
      (c) => c.countryId === data.MobileNumberPrefix
    );
    const phonePrefix = this.callingCodes.find(
      (c) => c.countryId === data.PhoneNumberPrefix
    );
    const faxPrefix = this.callingCodes.find(
      (c) => c.countryId === data.FaxNumberPrefix
    );

    let newImageUrl;
    if (this.saveImageUser) {
      await new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', this.saveImageUser);

        this.uploader
          .singleUploadImage(formData)
          .pipe(take(1))
          .subscribe(
            (res: any) => {
              newImageUrl = res.data;
              resolve(newImageUrl);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }

    delete data.Image;

    const payload = {
      ...data,
      CountryId: data.CountryName.countryId,
      CountryName: data.CountryName.countryName,
      StateId: data.StateName.stateId,
      StateName: data.StateName.stateName,
      CityId: data.CityName.cityId,
      CityName: data.CityName.cityName,
      PhoneNumberPrefix: phonePrefix && phonePrefix.callingCode,
      MobileNumberPrefix: mobilePrefix && mobilePrefix.callingCode,
      FaxNumberPrefix: faxPrefix && faxPrefix.callingCode,
      Image: this.saveImageUser ? newImageUrl : '',
    };
    this.contactService.addContacts(payload).subscribe(
      (res) => {
        if (res) {
          this._fuseSplashScreenService.hide();
          this._snackBar.openFromComponent(SnackbarComponent, FuseUtils.snackBarConfig(
            "Success!",
            "success",
            res?.message ?? `Added Successfully.`
          ))
          this.dialogRef.close();
        }
      },
      (error) => {
        console.warn(error);
        this._fuseSplashScreenService.hide();
        this.openPopUpModal({
          data: 'error',
          title: 'ERROR !',
          description: error,
        });
      }
    );
  }
  openPopUpModal(data) {
    return this.dialog.open(PopUpComponent, {
      width: '20%',
      panelClass: 'contact-form-dialog',
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
        showDelete: data.showDelete,
      },
    });
  }
}
