import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SelectTypeConsigneeDialogComponent } from '../select-type-consignee-dialog/select-type-consignee-dialog.component';
import { SelectConsigneeDialogComponent } from '../select-consignee-dialog/select-consignee-dialog.component';

@Component({
  selector: 'app-guest-consignee-dialog',
  templateUrl: './guest-consignee-dialog.component.html',
  styleUrls: ['./guest-consignee-dialog.component.scss']
})
export class GuestConsigneeDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GuestConsigneeDialogComponent>,
    private dialog: MatDialog,
  ) {
    this.dialogRef.disableClose = true;
   }

  ngOnInit(): void {
  }

  openSelectTypeConsignee(): void {
    this.dialogRef.close();
    this.dialog.open(SelectTypeConsigneeDialogComponent, {
      autoFocus: null,
      width: '500px'
    });
  }

  openSelectConsignee(): void {
    this.dialogRef.close();
    this.dialog.open(SelectConsigneeDialogComponent, {
      autoFocus: null,
      width: '500px'
    });
  }

}
