import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { navigation } from "app/navigation/navigation";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationTurkish } from "app/navigation/i18n/tr";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ProfileService } from "app/services/profile/profile.service";
import { select, Store } from "@ngrx/store";
import { RootState, selectCompany } from "./core/store";
import { MessagingService } from "app/services/messaging/messaging.service";
import { MatDialog } from "@angular/material/dialog";
import { TripDetailsEmergencyModalComponent } from "./main/apps/transaction-management/service-ticket-trucking/track-and-trace-trucking/trip-details-emergency-modal/trip-details-emergency-modal.component";
import { SosModalComponent } from "./main/apps/transaction-management/service-ticket-trucking/sos-modal/sos-modal.component";
import { ActualUpdateMap } from "./main/apps/transaction-management/trip-management/trip-details/trip-details.component";
import { RouteCompletionComponent } from "./layout/components/reusable/route-completion/route-completion.component";
import { Router } from "@angular/router";

@Component({
  selector: "app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;
  companyId: any;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private profileService: ProfileService,
    private store: Store<RootState>,
    private messagingService: MessagingService,
    private dialog: MatDialog,
    private _router: Router
  ) {
    this.profileService.getUser();
    this.profileService.initGetCompanyProfile();
    setTimeout(() => {
      this.store
        .pipe(select(selectCompany))
        .pipe(take(1))
        .subscribe((result) => {
          this.companyId = result && result.companyId;
          if (this.companyId != null) {
            this.realTimeUpdate(this.companyId);
          }
        });
    }, 500);

    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register("main", this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation("main");

    // Add languages
    this._translateService.addLangs(["en", "tr"]);

    // Set the default language
    this._translateService.setDefaultLang("en");

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationTurkish
    );

    // Use a language
    this._translateService.use("en");

    this.profileService.getUser();
    this.profileService.initGetCompanyProfile();

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     **/

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add("is-mobile");
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.addCustomIcons();
  }

  realTimeUpdate(companyId) {
    this.messagingService.RegisterDevice(companyId);

    // this.messagingService.getFireBaseData()
    this.messagingService.receiveMessage();
    let newNotif = this.messagingService.currentMessage;
    newNotif.subscribe((result: any) => {
      if (result) {
        const messageBody = JSON.parse(result.data.info);
        if (result?.notification.title === "SOS Notification With Trip") {
          this.emergency(messageBody);
        }
        if (result.notification.title === "Route Completion") {
          if (
            messageBody.TruckingCompanyGuid === companyId ||
            messageBody.BookingPartyGuid === companyId
          ) {
            this.routeModal(messageBody, "Route Completion");
          }
        }
      }
    });
    const channel = new BroadcastChannel("bgMsg");
    channel.addEventListener("message", (result: any) => {
      if (result) {
        const { data, notification } = result.data;
        const messageBody = JSON.parse(data.info);

        if (notification.title === "SOS Notification With Trip") {
          this.emergency(messageBody);
        }
        if (notification.title === "Route Completion") {
          if (
            messageBody.TruckingCompanyGuid === companyId ||
            messageBody.BookingPartyGuid === companyId
          ) {
            this.routeModal(messageBody, "Route Completion");
          }
        }
      }
    });
  }

  routeModal(message, title) {
    const modal = this.dialog.open(RouteCompletionComponent, {
      panelClass: "route-dialog",
      data: {
        info: message,
        TruckingCompanyId: this.companyId,
        dataTimer: "15",
        title: title,
      },
    });
  }

  emergency(message) {
    const modal = this.dialog.open(SosModalComponent, {
      width: "25%",
      panelClass: "trip-details",
      data: {
        data: message,
      },
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    let _this = this;
    localStorage.removeItem("tokenFirebase");
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === "boxed") {
          this.document.body.classList.add("boxed");
        } else {
          this.document.body.classList.remove("boxed");
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith("theme-")) {
            this.document.body.classList.remove(className);
          }
        }

        window.addEventListener("message", function (e) {
          if (e.data === "cyops") {
            _this._router.navigateByUrl(
              "/main/container-management/slot-booking",
              {
                replaceUrl: true,
              }
            );
          }
        });
        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  addCustomIcons(): void {
    // Custom icons

    this.iconRegistry.addSvgIcon(
      "xlog-document-folder",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/document/documentfolder.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "xlog-dashboard",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/Dashboard.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-profile",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/Profile.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-access-management",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/AccessManagement.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-fleet-management",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/FleetManagement.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-schedules",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/Schedule.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-billing-management",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/BillingManagement.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-service-contract",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/ServiceContract.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-ar-ap",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/AccessPayablesReceivables.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-settings",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/Settings.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-transaction-management",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/TransactionManagement.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-menu",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/menuicon.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-additional-info",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/additional-information.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-commodity",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/commodity.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-insurance",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/insurance.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-load-type",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/load-type.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-origin-destination",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/origin-destination.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-route",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/route.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-shipment-type",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/shipment-type.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-terms-of-payment",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/terms-of-payment.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-trip-summary",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/trip-summary.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-container-summary",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/container-summary.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-driver-details",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/driver-details.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-user-groups",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/User.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-download",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/Download.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-download-light",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/Download-light.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-vehicle",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/vehicle-icon.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-vehicle-light",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/vehicle-light.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-transaction-receivable",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/total-received-transaction.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-transaction-payable",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/total-payable-transaction.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "xlog-info-edit",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/common/edit.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "xlog-service-ticket",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/service-ticket.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "xlog-sample-qrcode",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/qrcode.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "xlog-service-rates",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/Services and rates.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "xlog-mi-upload-icon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/manage-invoice-upload-icon.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-mi-upload-icon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/manage-invoice-upload-icon.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "circle-checked-bg",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/common/checked.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "xlog-file-icon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/fileIcon.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "xlog-vessel-icon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/vessel.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-vessel-gray-icon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/vessel-gray.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-container-icon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/container.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "xlog-info-icon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/service-details/Info-Icon.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-info-icon-accent",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/service-details/info-icon-accent.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-rebook-icon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/booking-reservation/Rebook-icon.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-relink",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/sr_relink.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-reject-icon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/reject-cancellation.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-menu-icon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/svg/xlog-menu.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-container-management",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/sidebar/Container.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "xlog-success",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/xlog-icon/successIcon.svg"
      )
    );
  }
}
