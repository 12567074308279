import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SelectConsigneeDialogComponent } from '../select-consignee-dialog/select-consignee-dialog.component';
import { GuestConsigneeDialogComponent } from '../guest-consignee-dialog/guest-consignee-dialog.component';

@Component({
  selector: 'app-select-type-consignee-dialog',
  templateUrl: './select-type-consignee-dialog.component.html',
  styleUrls: ['./select-type-consignee-dialog.component.scss'],
})
export class SelectTypeConsigneeDialogComponent implements OnInit {
  consigneeList: any[];
  constructor(
    public dialogRef: MatDialogRef<SelectTypeConsigneeDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) _data: any
  ) {
    this.dialogRef.disableClose = true;
    if (_data) {
      this.consigneeList = _data.consigneeList;
    }
  }

  ngOnInit(): void {}

  openSelectConsignee(): void {
    this.dialogRef.close();
    this.dialog.open(SelectConsigneeDialogComponent, {
      width: '500px',
    });
  }

  selectConsignee(consignee): void {
    this.consigneeList.forEach((element) => {
      element.isSelected = false;
    });
    consignee.isSelected = true;
    this.dialogRef.close({
      consigneeList: this.consigneeList,
      consignee,
    });
  }

  openGuestConsignee(): void {
    this.dialogRef.close();
    this.dialog.open(GuestConsigneeDialogComponent, {
      width: '70%',
    });
  }
}
