<div
  class="navbar-top"
  [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
  <div class="logo">
    <span class="logo-text secondary-text"><b>Powered By</b></span>
    <img class="logo-icon" src="assets/images/logos/xlog-light.svg" />
  </div>

  <div class="buttons">
    <button
      mat-icon-button
      class="toggle-sidebar-folded"
      (click)="toggleSidebarFolded()"
      fxHide.lt-lg
    >
      <mat-icon class="secondary-text">menu</mat-icon>
    </button>

    <button
      mat-icon-button
      class="toggle-sidebar-opened"
      (click)="toggleSidebarOpened()"
      fxHide.gt-md
    >
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>
  </div>
</div>

<div
  class="navbar-scroll-container"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
  <div
    class="user"
    fxLayout="column"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
  >
    <div class="h3 username">{{ user?.name || "-" }}</div>
    <div class="h5 email hint-text mt-8">{{ user?.email || "-" }}</div>
    <div
      class="avatar-container"
      [ngClass]="fuseConfig.layout.navbar.primaryBackground"
    >
      <img
        class="avatar cursor-pointer"
        [src]="
          user?.image
            ? (user.image | protectedImage | async)
            : 'assets/images/logos/without-text/PNG/person.png'
        "
        [routerLink]="['/main/profile/my-profile']"
        alt="User"
        onerror="this.src='assets/images/logos/without-text/PNG/person.png'"
      />
    </div>
  </div>

  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
</div>
