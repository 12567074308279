import { Action } from '@ngrx/store';
import { ContainerList } from 'app/main/apps/container-management/component/container-management.type';

export enum ContainerActionTypes {
    SetContainer = '[Container] Set Container',
    RemoveContainer = '[Container] Remove Container',
}

export class SetContainer implements Action {
    readonly type = ContainerActionTypes.SetContainer;
    constructor(public payload: ContainerList) { }
}

export class RemoveContainer implements Action {
    readonly type = ContainerActionTypes.RemoveContainer;
    constructor() { }
}

export type ContainerActions = SetContainer | RemoveContainer;
