import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs'
 
@Injectable()
export class MessagingService {
 
currentMessage = new BehaviorSubject(null);
tokenFirebase = new BehaviorSubject(null);

 
constructor(private angularFireMessaging: AngularFireMessaging,     private http: HttpClient,
  ) {

    }
 ngOnInit(): void {
  this.angularFireMessaging.messages.subscribe(
    (_messaging: AngularFireMessaging) => {
    _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    })

 }
 requestPermission() {
  this.angularFireMessaging.requestToken.subscribe(
  (token) => {
    this.http.get<any>(`${environment.truckingService}/oat/api/v1/fcm/register/${token}/rel/topics/BookingSubmission`).toPromise()
    localStorage.setItem("tokenFireBase",token)
   // this.tokenFirebase.next(token);
  });
}
 
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (msg) => {
      this.currentMessage.next(msg);
     })
    }

    getFireBaseData(): Observable<any> {
      this.tokenFirebase
        .subscribe(result => {
         localStorage.setItem("tokenFireBase",result )
        return this.http.get<any>(`${environment.truckingService}/oat/api/v1/fcm/register/${result}/rel/topics/BookingSubmission`)
      })

      return;
       
    }

    requestDriverOnline(topic) {
      
      this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.http.get<any>(`${environment.truckingService}/oat/api/v1/fcm/register/${token}/rel/topics/${topic}`).toPromise()
        //this.tokenFirebase.next(token);   

      });
    }
    requestStartTrip(topic) {
      this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.http.get<any>(`${environment.truckingService}/oat/api/v1/fcm/register/${token}/rel/topics/${topic}`).toPromise()
        //this.tokenFirebase.next(token);   
      });
    }
    requestActualOrigin(topic) {
      this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.http.get<any>(`${environment.truckingService}/oat/api/v1/fcm/register/${token}/rel/topics/${topic}`).toPromise()
        //this.tokenFirebase.next(token);   
      });
    }
    requestActualDestination(topic) {
      this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.http.get<any>(`${environment.truckingService}/oat/api/v1/fcm/register/${token}/rel/topics/${topic}`).toPromise()
        //this.tokenFirebase.next(token);   
      });
    }
    latestLocationDriver(topic) {
      this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.http.get<any>(`${environment.truckingService}/oat/api/v1/fcm/register/${token}/rel/topics/${topic}`).toPromise()
        //this.tokenFirebase.next(token);   
      });
    }

    RegisterDevice(id) {
      this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.http.post<any>(`${environment.globalCMS}/global/cms/api/v1/pushNotif/device/register`, {sourceId: id, deviceToken: token}).toPromise()
        //this.tokenFirebase.next(token);   
      });
    }
    UnRegisterDevice(id) {

      this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        let options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          body: {sourceId: id, deviceToken: token}
        };
        this.http.delete<any>(`${environment.globalCMS}/global/cms/api/v1/pushNotif/device/unregister`, options ).toPromise()
        //this.tokenFirebase.next(token);   
      });
      localStorage.removeItem('reservationId'); 
    }

}
