<div fxLayout="column" fxFlex fusePerfectScrollbar>
  <mat-list cdkFocusRegionStart>
    <div fxLayout="column" fxLayoutAlign="center center" class="mb-8">
      <div>
        <img
          class="company-logo mr-0 mr-sm-16"
          [src]="
            company?.image
              ? (company.image | protectedImage | async)
              : 'assets/images/logos/without-text/PNG/company.png'
          "
          alt="company image"
          onerror="this.src='assets/images/logos/without-text/PNG/company.png'"
        />
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="h2 font-weight-900 mr-12 text-center">
          {{ company?.name || "-" }}
        </div>
        <div class="username mr-12 h6">{{ company?.service || "-" }}</div>
      </div>
    </div>
  </mat-list>

  <mat-divider cdkFocusRegionEnd></mat-divider>

  <mat-divider></mat-divider>

  <mat-list>
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <button mat-menu-item [routerLink]="['/main/profile/company-profile']">
        <mat-icon>account_circle</mat-icon>
        <span>Profile</span>
      </button>

      <button mat-menu-item class="" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </button>
    </div>
  </mat-list>
</div>
