import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SelectNotifyPartyDialogComponent } from '../select-notify-party-dialog/select-notify-party-dialog.component';
import { SelectTypeNotifyPartyDialogComponent } from '../select-type-notify-party-dialog/select-type-notify-party-dialog.component';

@Component({
  selector: 'app-guest-notify-party-dialog',
  templateUrl: './guest-notify-party-dialog.component.html',
  styleUrls: ['./guest-notify-party-dialog.component.scss']
})
export class GuestNotifyPartyDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GuestNotifyPartyDialogComponent>,
    private dialog: MatDialog,
  ) { 
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  openSelectTypeNotifyParty(): void {
    this.dialogRef.close();
    this.dialog.open(SelectTypeNotifyPartyDialogComponent, {
      autoFocus: null,
      width: '500px'
    });
  }

  openSelectNotifyParty(): void {
    this.dialogRef.close();
    this.dialog.open(SelectNotifyPartyDialogComponent, {
      autoFocus: null,
      width: '500px'
    });
  }

}
