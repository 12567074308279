import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SelectNotifyPartyDialogComponent } from '../select-notify-party-dialog/select-notify-party-dialog.component';
import { GuestNotifyPartyDialogComponent } from '../guest-notify-party-dialog/guest-notify-party-dialog.component';

@Component({
  selector: 'app-select-type-notify-party-dialog',
  templateUrl: './select-type-notify-party-dialog.component.html',
  styleUrls: ['./select-type-notify-party-dialog.component.scss']
})
export class SelectTypeNotifyPartyDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SelectTypeNotifyPartyDialogComponent>,
    private dialog: MatDialog
  ) {
    this.dialogRef.disableClose = true;
   }

  ngOnInit(): void {
  }

  openSelectNotifyParty(): void {
    this.dialogRef.close();
    this.dialog.open(SelectNotifyPartyDialogComponent, {
      autoFocus: null,
      width: '500px'
    })
  }

  openGuestNotifyParty(): void {
    this.dialogRef.close();
    this.dialog.open(GuestNotifyPartyDialogComponent, {
      autoFocus: null,
      width: '70%'
    })
  }

}
