<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && !rightNavbar"
        fxHide.gt-md
      ></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img
            class="logo-icon"
            src="assets/images/logos/xlog.svg"
            alt="logo"
          />
        </div>
      </div>
    </div>

    <div
      class="content-body"
      fxFlex="0 1 auto"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-menu #createBooking="matMenu" class="menu-style">
        <button
          mat-menu-item
          class="menu-item-style font-weight-600"
          (click)="openReferenceModal()"
        >
          Enter Reference No/s.
        </button>
        <span class="line-seperator">
          <h2><span>OR</span></h2>
        </span>
        <label
          mat-menu-item
          class="border-bottom-2 font-weight-600"
          (click)="$event.stopPropagation()"
          >Select Service Type</label
        >

        <button
          mat-menu-item
          [routerLink]="[
            '/main/booking-portal/booking-request/' +
              type.serviceName.toLowerCase() +
              '/' +
              type.serviceTypeId
          ]"
          class="menu-item-style"
          (click)="clicked()"
          *ngFor="let type of serviceTypes"
        >
          {{ type.serviceName }}
        </button>
        <button
          mat-menu-item
          class="menu-item-style font-weight-600"
          (click)="openSelectTruckingModal()"
        >
          Trucking
        </button>
        <button
          mat-menu-item
          class="menu-item-style font-weight-600 border-top-2 mt-2"
          (click)="clickSlotBooking()"
        >
          Slot Booking
        </button>
      </mat-menu>
      <button
        *ngIf="showCreate"
        mat-button
        class="accent create-booking"
        routerLinkActive="router-link-active"
        [matMenuTriggerFor]="createBooking"
      >
        Create Booking
      </button>
      <mat-menu #createBooking="matMenu" class="menu-style">
        <label
          mat-menu-item
          class="border-bottom-2 font-weight-600"
          (click)="$event.stopPropagation()"
          >Select Service Type</label
        >

        <button
          mat-menu-item
          [routerLink]="[
            '/main/booking-portal/booking-request/' +
              type.serviceName.toLowerCase() +
              '/' +
              type.serviceTypeId
          ]"
          class="menu-item-style"
          (click)="clicked()"
          *ngFor="let type of serviceTypes"
        >
          {{ type.serviceName }}
        </button>
        <button
          mat-menu-item
          class="menu-item-style font-weight-600 border-top-2 mt-2"
          (click)="clickSlotBooking()"
        >
          Slot Booking
        </button>
      </mat-menu>
      <button
        mat-button
        [matMenuTriggerFor]="userMenu"
        class="user-button"
        fxHide.xs
      >
        <div fxLayout="row" fxLayoutAlign="center center">
          <img
            class="company-logo mr-0 mr-sm-16"
            [src]="
              company?.image
                ? (company.image | protectedImage | async)
                : 'assets/images/logos/without-text/PNG/company.png'
            "
            alt="company image"
            onerror="this.src='assets/images/logos/without-text/PNG/company.png'"
          />
          <div fxLayout="column" fxLayoutAlign="center center">
            <span class="h2 font-weight-900 mr-12" fxHide fxShow.gt-sm>{{
              company?.name || "-"
            }}</span>
            <span class="username mr-12 h6" fxHide fxShow.gt-sm>{{
              company?.service || "-"
            }}</span>
          </div>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item [routerLink]="['/main/profile/company-profile']">
          <mat-icon>account_circle</mat-icon>
          <span>Profile</span>
        </button>

        <button mat-menu-item class="" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>

      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

      <button
        fxHide
        fxShow.xs
        mat-icon-button
        class="quick-panel-toggle-button"
        (click)="toggleSidebarOpen('quickPanel')"
        aria-label="Toggle quick panel"
      >
        <mat-icon svgIcon="xlog-menu-icon" class="s-20"></mat-icon>
      </button>

      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && rightNavbar"
        fxHide
        fxShow.gt-xs
      ></div>

      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
