import { CompanyActions, CompanyActionTypes } from './company.action';
import { Company } from '../../models/company.model';

// export interface State {
//   Companys: Company;
// }

export const initialState: Company = null;
export function reducer(state = initialState, action: CompanyActions): Company {
  switch (action.type) {
    case CompanyActionTypes.SetCompany:
      // state = action.payload;
      return { ...action.payload };
    case CompanyActionTypes.RemoveCompany:
      state = initialState;
      return state;
    default:
      return state;
  }
}
