<div class="p-24">
  <h1 matDialogTitle>Confirm</h1>
  <div mat-dialog-content>{{ confirmMessage }}</div>
  <div mat-dialog-actions>
    <button
      mat-raised-button
      class="mat-accent mr-16"
      (click)="dialogRef.close(true)"
    >
      Confirm
    </button>
    <button mat-button (click)="dialogRef.close(false)">Cancel</button>
  </div>
</div>
