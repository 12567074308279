import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TokenService } from './services/token/token.service';


import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers } from './core/store';
import { HttpInterceptorService } from './core/interceptor/http-interceptors';

import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CurrencyPipe } from '@angular/common';

import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'environments/environment';
import { MessagingService } from 'app/services/messaging/messaging.service';
import { ProtectedImageModule } from './core/pipe/protected-image/protected-image.module';
import { SnackbarComponent } from '@fuse/components/snackbar/snackbar.component';

const appRoutes: Routes = [
  {
    path: 'main',
    loadChildren: () =>
      import('./main/apps/apps.module').then((m) => m.AppsModule),
  },
  {
    path: 'transaction-management',
    loadChildren: () => import('./main/apps/transaction-management/transaction-management.module').then(m => m.TransactionManagementModule)
  },
  {
    path: '**',
    redirectTo: 'main',
  },
];

export function getToken(tokenService: TokenService) {
  return (): Promise<any> => {
    return tokenService.getTokenOnInit();
  };
}

@NgModule({
  declarations: [AppComponent, SnackbarComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

    TranslateModule.forRoot(),
    MatPasswordStrengthModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    SampleModule,
    FlexLayoutModule,
    NgxDaterangepickerMd,
    MatPasswordStrengthModule,
    ProtectedImageModule,
    // Ngx Date Time Picker used in BP Trucking Schedule
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,

    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    MessagingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: getToken,
      deps: [TokenService],
      multi: true,
    },
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
