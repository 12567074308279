import { Action } from '@ngrx/store';
import { TransactionTrucking } from 'app/core/models/transaction-trucking.model';

export enum TransactionTruckingActionTypes {
  SetTransactionTruckings = '[TransactionTrucking] Set TransactionTrucking',
  RemoveTransactionTrucking = '[TransactionTrucking] Remove TransactionTrucking',
}

export class SetTransactionTrucking implements Action {
  readonly type = TransactionTruckingActionTypes.SetTransactionTruckings;
  constructor(public payload: TransactionTrucking) {}
}

export class RemoveTransactionTrucking implements Action {
  readonly type = TransactionTruckingActionTypes.RemoveTransactionTrucking;
  constructor() {}
}

export type TransactionTruckingActions = SetTransactionTrucking | RemoveTransactionTrucking;
