<mat-toolbar class="accent-500 border-radius-bottom-8">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="font-weight-900 font-size-16">
        Add Registered -
        {{
          type == 0 ? "Shipper" : type == 1 ? "Consignee" : "Notify Party"
        }}</span
      >
      <div
        fxLayout="column"
        fxLayoutAlign="center"
        class="cursor-pointer"
        (click)="matDialogRef.close()"
        aria-label="Close dialog"
      >
        <mat-icon>cancel</mat-icon>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  
  <mat-dialog-content class="mat-typography">
    <div [formGroup]="inviteCodeForm">
      <div
        fxLayout="column"
        fxLayoutAlign="center"
        fxLayoutGap="12px"
        class="registered-shipper-container pt-14 "
        fxFlex="1 0 auto"
      >
      <!-- class="py-52 px-80" -->
      
        <mat-form-field floatLabel="always" appearance="outline" fxFlex>
          <mat-label>Invite Code</mat-label>
          <input matInput minlength="5" maxlength="5" formControlName="code"/>
            <mat-error *ngIf="inviteCodeForm.get('code').hasError('minlength')">
              Invite Code must be atleast 5 characters.
            </mat-error>
            <mat-error *ngIf="inviteCodeForm.get('code').hasError('maxlength')">
              Invite Code must not exceed 5 characters.
            </mat-error>
        </mat-form-field>
  
        
        <div *ngIf="loading">
          <mat-spinner class="m-auto" diameter="35"></mat-spinner>
        </div>
  
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="1 0 auto" *ngIf="senderDetails" class="m-8">
        <div *ngIf="!loading">
            <div  fxLayout="row" fxLayoutGap="12px">
            <div fxLayout="column" fxLayoutAlign="start center" fxFlex>
                <div class="board-list-item-registered" fxLayoutAlign="center center">
                  <img
                    class="image-size"
                    [src]="senderDetails.imageUrl ? (senderDetails.imageUrl | protectedImage | async) : 'assets/images/logos/without-text/SVG/company.svg' "
                    onerror="this.src='assets/images/logos/without-text/SVG/company.svg';"
                    alt="Registered Company Logo"
                  />
                </div>
              </div>
              <div class="ml-20 font-size-29" fxLayout="column">                
                <span class="font-weight-700">
                  {{ senderDetails.companyName }}
                </span>
                <span
                  >{{ senderDetails.companyAddress }}</span
                >
              </div>
            </div>
          </div>
        </div>
  
        <div fxLayout="row" fxLayoutAlign="end end" class="mt-12">
          <button mat-button class="grey-600 mr-8" (click)="matDialogRef.close()">
            <mat-icon>
              clear
            </mat-icon>
            Cancel
          </button>
          <button mat-button class="blue-500" (click)="addRegisteredContact()" [disabled]="inviteCodeForm.invalid">
            <mat-icon>
              keyboard_arrow_right
            </mat-icon>
            Proceed
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  