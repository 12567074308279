import { Action } from '@ngrx/store';
import { Profile } from '../../models/profile.model';

export enum ProfileActionTypes {
  SetProfile = '[Profile] Set Profile',
  RemoveProfile = '[Profile] Remove Profile',
}

export class SetProfile implements Action {
  readonly type = ProfileActionTypes.SetProfile;
  constructor(public payload: Profile) {}
}

export class RemoveProfile implements Action {
  readonly type = ProfileActionTypes.RemoveProfile;
  constructor() {}
}

export type ProfileActions = SetProfile | RemoveProfile;
