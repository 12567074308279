<mat-toolbar>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="center"
    fxFlex
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxShow.gt-xs>
      <p class="mat-caption">Copyright &copy; XLOG</p>
      <img class="logo-icon custom-icon" src="assets/images/logos/xlog.svg" />
      <p class="mat-caption">All Rights Reserved</p>
    </div>
  </div>
</mat-toolbar>
