import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SelectShipperDialogComponent } from '../select-shipper-dialog/select-shipper-dialog.component';
import { GuestShipperDialogComponent } from '../guest-shipper-dialog/guest-shipper-dialog.component';
import { RegisteredShipperDialogComponent } from '../registered-shipper-dialog/registered-shipper-dialog.component';

@Component({
  selector: 'app-select-type-shipper-dialog',
  templateUrl: './select-type-shipper-dialog.component.html',
  styleUrls: ['./select-type-shipper-dialog.component.scss'],
})
export class SelectTypeShipperDialogComponent implements OnInit {
  type: any;
  constructor(
    public dialogRef: MatDialogRef<SelectTypeShipperDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) _data: any
  ) {
    this.dialogRef.disableClose = true;
    if (_data) {
      this.type = _data.type;
    }
  }

  ngOnInit(): void {}

  openSelectShipper(): void {
    this.dialogRef.close();
    this.dialog.open(RegisteredShipperDialogComponent, {
      width: '50%',
      data: {
        type: this.type
      }
    });
  }

  openGuestShipper(): void {
    this.dialogRef.close();
    this.dialog.open(GuestShipperDialogComponent, {
      width: '70%',
      data: {
        type: this.type,
      },
    });
  }
}
