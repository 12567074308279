import { TransactionTrucking } from 'app/core/models/transaction-trucking.model';
import { TransactionTruckingActions, TransactionTruckingActionTypes } from './transaction-trucking.action';


export const initialState: TransactionTrucking = null;
export function reducer(state = initialState, action: TransactionTruckingActions): TransactionTrucking {
  switch (action.type) {
    case TransactionTruckingActionTypes.SetTransactionTruckings:
      state = action.payload;
      return state;
    case TransactionTruckingActionTypes.RemoveTransactionTrucking:
      state = initialState;
      return state;
    default:
      return state;
  }
}
