import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import { storageSync } from '@larscom/ngrx-store-storagesync';
import * as fromUser from './user/user.reducer';
import * as fromCompany from './company/company.reducer';
import * as fromProfile from './profile/profile.reducer';
import * as fromPort from './port/port.reducer';
import * as fromMenuPermission from './menu-permission/menu-permission.reducer';
import * as fromReservation from './reservation/reservation.reducer';
import * as fromTransactionTrucking from './transaction-trucking/transaction-trucking.reducer'
import * as fromSeaFreightInbound from './booking-sea-freight-inbound/sea-freight-inbound.reducer'
import * as fromContainerReducer from './container/container.reducer';
import { User } from '../models/user.model';
import { Company } from '../models/company.model';
import { Profile } from '../models/profile.model';
import { Port } from '../models/port.model';
import { Booking } from '../models/booking.model';
import * as fromBooking from './booking/booking.reducer';
import { MenuPermission } from '../models/menu-permission.model';
import { from } from 'rxjs';
import { BookingTrucking, CreateRequestBookingTrucking } from '../models/booking-trucking.models';
import * as fromBookingTrucking from './booking-trucking/booking-trucking.reducer';
import { TransactionTrucking } from '../models/transaction-trucking.model';
import { CreateRequestBookingSeaFreightInbound } from '../models/booking-sea-freight-inbound.model';
import { ContainerList } from 'app/main/apps/container-management/component/container-management.type';

export interface RootState {
  user: User;
  company: Company;
  profile: Profile;
  port: Port[];
  booking: Booking;
  menuPermission: MenuPermission[];
  reservation: Booking;
  bookingTrucking: CreateRequestBookingTrucking;
  transactionTrucking: TransactionTrucking;
  bookingSeaFreightInbound: CreateRequestBookingSeaFreightInbound;
  containerRequest: ContainerList;
}

export const reducers: ActionReducerMap<RootState> = {
  user: fromUser.reducer,
  company: fromCompany.reducer,
  profile: fromProfile.reducer,
  port: fromPort.reducer,
  booking: fromBooking.reducer,
  menuPermission: fromMenuPermission.reducer,
  reservation: fromReservation.reducer,
  bookingTrucking: fromBookingTrucking.reducer,
  transactionTrucking: fromTransactionTrucking.reducer,
  bookingSeaFreightInbound: fromSeaFreightInbound.reducer,
  containerRequest: fromContainerReducer.reducer
};

export function storageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return storageSync<RootState>({
    features: [
      { stateKey: 'user' },
      { stateKey: 'profile' },
      { stateKey: 'company' },
      { stateKey: 'port' },
      { stateKey: 'booking' },
      { stateKey: 'menuPermission' },
      { stateKey: 'reservation' },
      { stateKey: 'bookingTrucking' },
      { stateKey: 'transactionTrucking' },
      { stateKey: 'bookingSeaFreightInbound' },
      { stateKey: 'containerRequest' }
    ],
    storage: window.localStorage,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [storageSyncReducer];

// Users
export const selectUserState = createFeatureSelector<User>('user');

export const selectUser = createSelector(selectUserState, (state) => state);

// Company
export const selectCompanyState = createFeatureSelector<Company>('company');

export const selectCompany = createSelector(
  selectCompanyState,
  (state) => state
);

// Profile
export const selectProfileState = createFeatureSelector<Profile>('profile');

export const selectProfile = createSelector(
  selectProfileState,
  (state) => state
);

// Port
export const selectPortState = createFeatureSelector<Port[]>('port');

export const selectPort = createSelector(selectPortState, (state) => state);

// Booking
export const selectBookingState = createFeatureSelector<Booking>('booking');

export const selectBooking = createSelector(
  selectBookingState,
  (state) => state
);

// Menu Permission
export const selectMenuPermissionState = createFeatureSelector<MenuPermission[]>('menuPermission');

export const selectMenuPermission = createSelector(selectMenuPermissionState, (state) => state);

// Booking
export const selectReservationState = createFeatureSelector<Booking>('reservation');

export const selectReservation = createSelector(
  selectReservationState,
  (state) => state
);

// Booking
export const selectBookingTruckingState = createFeatureSelector<CreateRequestBookingTrucking>('bookingTrucking');

export const selectBookingTrucking = createSelector(
  selectBookingTruckingState,
  (state) => state
);

// Trucking
export const selectTransactionTruckingState = createFeatureSelector<TransactionTrucking>('transactionTrucking');

export const selectTransactionTrucking = createSelector(
  selectTransactionTruckingState,
  (state) => state
);

// Sea-Freight Inbound
export const selectBookingSeaFreightInboundState = createFeatureSelector<CreateRequestBookingSeaFreightInbound>('bookingSeaFreightInbound');

export const selectBookingSeaFreightInbound = createSelector(
  selectBookingSeaFreightInboundState,
  (state) => state
);

//Container Request
export const selectContainerRequestState = createFeatureSelector<ContainerList>('containerRequest');

export const selectContainerRequest = createSelector(
  selectContainerRequestState,
  (state) => state
);