<div class="dialog-content-wrapper m-0 p-0" inner-scroll>
  <mat-toolbar matDialogTitle class="accent p-12 mb-0">
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title"> {{ data.title }}</span>
        <div fxLayout="row" fxLayoutGap="8px">
          <button
            mat-button
            class="fuse-white accent-500-fg h-32 border-radius-16 pt-4"
            fxLayout="row"
            fxLayoutAlign="center"
            (click)="showTypeNotifyParty()"
          >
            <mat-icon>add</mat-icon>
            <span>Add Notify Party </span>
          </button>
          <div
            fxLayout="column"
            fxLayoutAlign="center"
            class="cursor-pointer"
            (click)="matDialogRef.close()"
            aria-label="Close dialog"
          >
            <mat-icon>cancel</mat-icon>
          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  <div class="user-access-container page-layout carded">
      <div
        fxLayout="column"
        fxLayoutAlign="center stretch"
        [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
        class="p-24"
      >

      <div fxLayout="row" class="py-8 px-12 border w-100-p border-radius-8">
        <input type="text" matInput placeholder="Search" fxFlex [formControl]="searchFilter" />
        <mat-icon class="color: gray;" matSuffix>search</mat-icon>
      </div>

      <div *ngIf="loading" class="p-24">
          <mat-spinner class="m-auto" diameter="35"></mat-spinner>
      </div>

      <div *ngIf="!loading">
        <div class="user-access-container page-layout carded pt-8">
          <!-- LISTING DATA -->
          <div
            class="listingNotifyParty"
            fxLayout="column"
            fxLayoutAlign="start"
          >
            <div
              class="w-100-p py-8 px-8"
              fxLayout="column"
              *ngIf="duplicateChecker()"
            >
              <span *ngFor=" let company of unselectCompany; let i = index">
                <div
                  class="select-item p-8 fullwidth w-100-p mat-elevation-z2"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  [@animate]="{
                    value: '*',
                    params: { delay: '300ms', x: '100%' }
                  }"
                  *ngIf="companyStatus(company)"
                >
                  <div fxFlex>
                    <div class="font-size-10" fxLayout="row" fxLayoutGap="12px">
                      <div class="board-list-item" fxLayoutAlign="center center">
                        <img class="image-size" [src]="company.imageUrl" />
                      </div>
                      <div class="mt-12 ml-12 font-size-29" fxLayout="column">
                        <span class="font-weight-700">
                          {{ company?.contactName }}
                        </span>
                        <span>
                          {{ company?.contactType }} (Booking Party) <br />
                          {{ company?.cityProvince }},
                          {{ company?.country }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <button
                    fxFlex
                    mat-button
                    class="w-100 p-8"
                    [ngClass]="{
                      'blue-500': !companyStatus(company),
                      'green-600': companyStatus(company)
                    }"
                    (click)="unselectedCompany(i)"
                  >
                    {{ company?.isSelected ? "Selected" : "Select" }}
                  </button>
                </div>
              </span>
            </div>
            <div
              class="w-100-p py-8 px-8"
              fxLayout="column"
            >
              <ng-container  *ngFor="let notifyParty of multipleSelectedNotifyParty; let i = index">
                <div
                  class="select-item p-8 fullwidth w-100-p mat-elevation-z2 my-12"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
                  *ngIf="notifyParty.isSelected == true"
                >
                  <div fxFlex>
                    <div class="font-size-10" fxLayout="row" fxLayoutGap="12px">
                      <div
                        class="board-list-item"
                        fxLayoutAlign="center center"
                      >
                        <img
                          class="image-size"
                          [src]="notifyParty.imageUrl"
                        />
                      </div>
                      <div class="mt-12 ml-12 font-size-29" fxLayout="column">
                        <span class="font-weight-700">
                          {{ notifyParty.contactName }}
                        </span>
                        <span>
                          {{ notifyParty.contactType }} <br>
                          {{ notifyParty.cityProvince }},
                          {{ notifyParty.country }}
                        </span>
                      </div>
                    </div>
                  </div>
      
                  <button
                    fxFlex
                    mat-button
                    class="w-100 p-8"
                    [ngClass]="{
                      'blue-500': !notifyParty.isSelected,
                      'green-600': notifyParty.isSelected
                    }"
                    (click)="unselectedNotifyParty(i)"
                  >
                    {{ notifyParty?.isSelected ? "Selected" : "Select" }}
                  </button>
                </div>
              </ng-container>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="hr || unselectCompany.length !== 0">
              <hr
                class="mt-12 mb-12"
                style="
                  height: 2px;
                  width: 80%;
                  border-width: 0;
                  background-color: #f0f0f0;
                "
              />
            </div>

            <div
              class="w-100-p py-8 px-8"
              fxLayout="column"
              *ngIf="duplicateChecker()"
            >
              <span *ngFor=" let company of selectCompany; let i = index">
                <div
                  class="select-item p-8 fullwidth w-100-p mat-elevation-z2"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  [@animate]="{
                    value: '*',
                    params: { delay: '300ms', x: '100%' }
                  }"
                  *ngIf="!companyStatus(company)"
                >
                  <div fxFlex>
                    <div class="font-size-10" fxLayout="row" fxLayoutGap="12px">
                      <div class="board-list-item" fxLayoutAlign="center center">
                        <img class="image-size" [src]="company.imageUrl" />
                      </div>
                      <div class="mt-12 ml-12 font-size-29" fxLayout="column">
                        <span class="font-weight-700">
                          {{ company?.contactName }}
                        </span>
                        <span>
                          {{ company?.contactType }} (Booking Party) <br />
                          {{ company?.cityProvince }},
                          {{ company?.country }}
                        </span>
                      </div>
                    </div>
                  </div>
  
                  <button
                    fxFlex
                    mat-button
                    class="w-100 p-8"
                    [ngClass]="{
                      'blue-500': !company.isSelected,
                      'green-600': company.isSelected
                    }"
                    (click)="selectedCompany(i)"
                  >
                    {{ company?.isSelected ? "Selected" : "Select" }}
                  </button>
                </div>
              </span>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="selectCompany.length !== 0">
              <hr
                class="mt-12 mb-12"
                style="
                  height: 2px;
                  width: 80%;
                  border-width: 0;
                  background-color: #f0f0f0;
                "
              />
            </div>
    
            <div
              class="w-100-p py-8 px-8"
              fxLayout="column"
            >
            <ng-container  *ngFor="let notifyParty of filteredArray; let i = index">
              <div
                class="select-item p-8 fullwidth w-100-p mat-elevation-z2 my-12"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
                *ngIf="notifyParty.isSelected == false"
              >
                <div fxFlex>
                  <div class="font-size-10" fxLayout="row" fxLayoutGap="12px">
                    <div
                      class="board-list-item"
                      fxLayoutAlign="center center"
                    >
                      <img
                        class="image-size"
                        [src]="notifyParty.imageUrl"
                      />
                    </div>
                    <div class="ml-20 font-size-29" fxLayout="column">
                      <span class="font-weight-700">
                        {{ notifyParty.contactName }}
                      </span>
                      <span>
                        {{ notifyParty.contactType }} <br>
                        {{ notifyParty.cityProvince }},
                        {{ notifyParty.country }}
                      </span>
                    </div>
                  </div>
                </div>
    
                <button
                  fxFlex
                  mat-button
                  class="w-100 p-8"
                  [ngClass]="{
                    'blue-500': !notifyParty.isSelected,
                    'green-600': notifyParty.isSelected
                  }"
                  (click)="selectNotifyParty(i)"
                >
                  {{ notifyParty?.isSelected ? "Selected" : "Select" }}
                </button>
              </div>
            </ng-container>
            </div>
          </div>
          <!-- / Listing Data -->
        </div>
        <div
          *ngIf="!filteredArray.length"
          class="text-center py-24 font-size-16 no-results"
        >
          No result found
        </div>
      </div>

      <mat-paginator
        fxLayout="row"
        fxLayoutAlign="center center"
        #paginator
        [length]="dataLength"
        [pageIndex]="pageNumber"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="pageEvent = $event; handlePage($event)"
      >
      </mat-paginator>

      <div
        class
        fxLayout="row"
        fxLayoutAlign="flex-end"
        fxLayoutGap="10px"
        class="p-20"
        style="padding-bottom: 0!important;"
      >
        <button mat-button class="grey-600" (click)="matDialogRef.close()">
          <mat-icon>
            clear
          </mat-icon>
          Cancel
        </button>
        <button mat-button class="blue-500" (click)="addNotifyParty()">
          <mat-icon>
            add_circle
          </mat-icon>
          Add
        </button>
      </div>

      </div>
  </div>
</div>