import { BookingTruckingActions, BookingTruckingActionTypes } from './booking-trucking.action';
import { BookingTrucking, CreateRequestBookingTrucking} from 'app/core/models/booking-trucking.models';

// export interface State {
//   Bookings: Booking;
// }

export const initialState: CreateRequestBookingTrucking = null;
export function reducer(state = initialState, action: BookingTruckingActions): CreateRequestBookingTrucking {
  switch (action.type) {
    case BookingTruckingActionTypes.SetBookingTrucking:
      state = action.payload;
      return state;
    case BookingTruckingActionTypes.RemoveBookingTrucking:
      state = initialState;
      return state;
    default:
      return state;
  }
}
