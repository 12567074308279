import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';

import { FuseSharedModule } from '@fuse/shared.module';

import { ChangePasswordComponent } from 'app/layout/components/change-password/change-password.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

@NgModule({
    declarations: [
        ChangePasswordComponent
    ],
    imports     : [
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatDialogModule,
        FuseSharedModule,
        MatPasswordStrengthModule
    ],
    exports: [
        ChangePasswordComponent
    ]
})
export class ChangePasswordModule
{
}
